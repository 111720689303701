<template>
    <AdvancedInputModal
        title="Bulk class importer"
        tag="span"
        :show="showModal"
        :showButton="showButton"
        @onConfirm="handleConfirm"
        @onCancel="$emit('onCancel')"
        @onToggle="$emit('onToggle', $event)">
        <font-awesome-icon
            slot="toggleModal"
            class="secondary-btn add-btn ml-2"
            icon="list"
            v-b-tooltip.hover="{
                title: 'Bulk add children to this class',
                boundary: 'window',
            }" />
        <AdvancedListInput
            slot="content"
            @onListMode="handleListModeToggle"
            v-model="listContent" />
    </AdvancedInputModal>
</template>

<script>
import AdvancedInputModal from '../AdvancedInputModal';
import AdvancedListInput from '../AdvancedListInput';

export default {
    data() {
        return {
            listContent: '',
            isListMode: false,
        };
    },
    name: 'BulkAddClassesModal',
    props: {
        showModal: {
            type: Boolean,
            default: false,
        },
        showButton: {
            type: Boolean,
            default: true,
        },
    },
    components: {
        AdvancedInputModal,
        AdvancedListInput,
    },
    methods: {
        handleConfirm(event) {
            if (this.isListMode) {
                this.listContent.shift();
            }
            this.$emit('onConfirm', this.listContent);
            this.listContent = '';
        },
        handleListModeToggle(event) {
            this.isListMode = event;
        },
    },
};
</script>

<style scoped></style>
