<template>
    <component class="advanced-input-modal" :is="tag">
        <span @click="toggleModal" v-if="showButton">
            <slot name="toggleModal">
                <b-button :class="btnClass">
                    <font-awesome-icon :icon="icon" />
                </b-button>
            </slot>
        </span>

        <b-modal
            v-model="modalShow"
            ref="modal"
            :title="title"
            :class="modalClass"
            @show="handleShow"
            @hidden="handleHidden"
            @ok="handleConfirm"
            @cancel="handleCancel"
            scrollable>
            <slot name="content"></slot>

            <template v-slot:modal-footer>
                <b-button
                    size="sm"
                    variant="secondary"
                    @click="handleCancel"
                    :disabled="disableCancel">
                    {{ footerCancelText }}
                </b-button>
                <b-button
                    size="sm"
                    variant="primary"
                    @click="handleConfirm"
                    :disabled="disableConfirm">
                    {{ footerConfirmText }}
                </b-button>

                <slot name="footer"></slot>
            </template>
        </b-modal>
    </component>
</template>

<script>
export default {
    data() {
        return {
            modalShow: false,
        };
    },
    props: {
        tag: {
            type: String,
            default: 'div',
        },
        icon: {
            type: String,
            default: 'list-alt',
        },
        btnClass: {
            type: String,
            default: '',
        },
        modalClass: {
            type: String,
            default: '',
        },
        title: {
            type: String,
            default: '',
        },
        footerConfirmText: {
            type: String,
            default: 'CONFIRM',
        },
        footerCancelText: {
            type: String,
            default: 'CANCEL',
        },
        showButton: {
            type: Boolean,
            default: true,
        },
        show: {
            type: Boolean,
            default: undefined,
        },
        disableConfirm: {
            type: Boolean,
            default: false,
        },
        disableCancel: {
            type: Boolean,
            default: false,
        },
    },
    mounted() {
        if (this.show) {
            this.modalShow = true;
        }
    },
    watch: {
        show(modalShow) {
            this.modalShow = modalShow;
        },
    },
    methods: {
        toggleModal(evt) {
            this.modalShow = !this.modalShow;
            this.$emit('onToggle', this.modalShow);
        },
        handleHidden(evt) {
            this.$emit('onHidden', evt);
        },
        handleShow(evt) {
            this.$emit('onShow', evt);
        },
        handleConfirm(evt) {
            this.modalShow = false;
            this.$emit('onConfirm', evt);
        },
        handleCancel(evt) {
            this.modalShow = false;
            this.$emit('onCancel', evt);
        },
    },
};
</script>

<style lang="scss" scoped>
@import 'src/scss/variables.scss';
::v-deep .modal-header {
    color: $secondary;
    button.close {
        color: $secondary;
    }
}
</style>
