import Vue from 'vue';

export default {
    methods: {
        /**
         * Inserts a component inside a given DOM element.
         * @param {Element} domElement The element the component is inserted in.
         * @param {Object} injectedComponent The component that we need to inject.
         * @param {Object} options Vue component options.
         * @returns {Vue} The inserted component.
         */
        injectComponent(domElement, injectedComponent, options) {
            const componentName = injectedComponent.name;
            const defaultOptions = {
                data: {},
                props: {},
            };

            const injectedProps = { ...options.props };
            const injectedListeners = { ...options.listeners };

            options = { ...defaultOptions, ...options };

            options.data = { ...options.data, ...options.props };

            delete options.props;
            delete options.listeners;

            const componentInstance = new Vue({
                parent: this,
                template: this.buildTemplateString(
                    componentName,
                    injectedProps
                ),
                components: { [componentName]: injectedComponent },
                computed: {
                    inputListeners: function () {
                        return Object.assign(
                            {},
                            this.$listeners,
                            injectedListeners
                        );
                    },
                },
                ...options,
            });

            componentInstance.$mount(domElement);

            return componentInstance;
        },

        /**
         * Builds the JSX of the injected component's template.
         * @param {String} componentName The name of the component that is injected.
         * @param {Object} injectedProps The props of the component.
         * @returns {string} The component's template in form of jsx string.
         */
        buildTemplateString(componentName, injectedProps) {
            const iterableProps = Object.entries(injectedProps);
            let templateString = `<${componentName} v-on="inputListeners"`;

            if (!iterableProps.length) return templateString + ' />';

            iterableProps.forEach((prop) => {
                const propName = prop[0];
                templateString += ` :${propName}="${propName}"`;
            });

            return templateString + ' />';
        },
    },
};
