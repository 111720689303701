<template>
    <div class="bulk-text-input input-group">
        <div class="input-group" v-if="!listModeToggled">
            <slot name="input-prepend">
                <div class="input-group-prepend"></div>
            </slot>

            <b-form-textarea
                class="rounded-left"
                :class="classes"
                :max-rows="textAreaMaxRows"
                :placeholder="placeholder"
                :rows="textAreaRows"
                v-model="textAreaContent"
                @paste="onPaste"></b-form-textarea>

            <div class="input-group-append">
                <b-button
                    variant="primary"
                    class="rounded-right"
                    @click="enableListMode($event)"
                    v-b-tooltip.hover="{
                        title: 'This will toggle between a list editor and a regular text area.',
                        delay: { show: showDelay, hide: 0 },
                    }">
                    <font-awesome-icon icon="list" />
                </b-button>
            </div>

            <slot name="input-append">
                <div class="input-group-append"></div>
            </slot>
        </div>

        <b-list-group class="keywords-list" :class="bulkAddClasses" v-else>
            <b-list-group-item v-if="hasAdd">
                <div class="util-buttons d-flex">
                    <b-button variant="info" @click="onAddToList">
                        <font-awesome-icon icon="plus" />
                    </b-button>
                    <b-button @click="disableListMode" variant="primary">
                        <font-awesome-icon icon="list" />
                    </b-button>
                </div>
            </b-list-group-item>

            <slot name="top-list-item"></slot>

            <b-list-group-item
                v-for="(keywords, index) in formattedList"
                :key="index"
                @click="toggleKeywordInput(index, $event)"
                class="keywords"
                :variant="isActive(index) ? 'primary' : ''">
                <div class="input-group" v-if="isActive(index)">
                    <input
                        type="text"
                        :ref="`list-input-${index}`"
                        class="form-control keyword-input"
                        @keyup.enter="onKeywordsChange(index, $event)"
                        @keydown.up="traverseUp(index, $event)"
                        @keydown.down="traverseDown(index, $event)"
                        @keydown.right="autoFill(index, $event)"
                        :placeholder="keywords"
                        v-model="listInput" />
                    <b-button
                        class="rounded-right input-group-append confirm-list-input"
                        variant="primary"
                        @click="onKeywordsChange(index, $event)">
                        Ok
                    </b-button>
                </div>

                <template v-else>
                    {{ keywords }}
                </template>
                <b-button class="clear-btn close" @click="deleteKeywords(index)"
                    >&times;</b-button
                >
            </b-list-group-item>

            <slot name="bottom-list-item"></slot>
        </b-list-group>
    </div>
</template>

<script>
export default {
    name: 'AdvancedListInput',
    data() {
        return {
            formattedList: [],
            listInput: '',
            activeKeywordsIndex: null,
            listModeToggled: false,
            textAreaContent: '',
            listContent: [],
        };
    },
    props: {
        showDelay: {
            type: Number,
            default: parseInt(process.env.VUE_APP_SHOW_DELAY),
        },
        textAreaRows: {
            type: String,
            default: '3',
        },
        textAreaMaxRows: {
            type: String,
            default: '6',
        },
        defaultStyle: {
            type: Boolean,
            default: false,
        },
        toggleListMode: {
            type: Boolean,
            default: false,
        },
        placeholder: {
            type: String,
            default: '',
        },
        classes: {
            type: String,
            default: '',
        },
        bulkAddClasses: {
            type: String,
            default: '',
        },
        hasAdd: {
            type: Boolean,
            default: true,
        },
        separator: {
            type: String,
            default: '\n',
        },
        trimmed: {
            type: Boolean,
            default: true,
        },
    },
    watch: {
        /**
         * Formats the text area and syncs with listContent
         */
        textAreaContent: function () {
            this.formattedList = this.formatToList(this.textAreaContent);
            this.listContent = this.formattedList;
        },
        /**
         * Syncs the formattedList with list content
         */
        formattedList: function () {
            this.listContent = this.formattedList;
            this.$emit('input', this.listContent);
        },
    },
    computed: {
        activeInputRef() {
            return `list-input-${this.activeKeywordsIndex}`;
        },
    },
    methods: {
        /**
         * Is a handler for onPaste on the text area
         * @param {Event} evt - Used to get the text of the paste event
         */
        onPaste(evt) {
            this.$emit('onPaste', evt);
        },
        /**
         * Can be called to change the current input to a different item in the list
         * @param {Number} inputIndex - The index you wish to change to
         */
        toggleKeywordInput(inputIndex) {
            this.activeKeywordsIndex = inputIndex;
            this.listInput = '';
            this.focusOpenInput();
        },
        /**
         * Is used to determine whether the keyword is a edit or a new input
         * then changes that index in the list to the user input
         * @param {Number} index - index used to determine which item in the list to change
         * @param {Event} evt - evt is used for its input value
         */
        onKeywordsChange(index, evt) {
            if (this.listInput === '') {
                return;
            }

            // if the prop trimmed is enabled then trim input
            const listItem = this.trimmed
                ? this.$refs[this.activeInputRef][0].value.trim()
                : this.$refs[this.activeInputRef][0].value;

            // Is it an edit or a new entry
            if (this.formattedList[index] === '') {
                this.formattedList[index] = listItem;
                this.onAddToList();
            } else {
                this.formattedList[index] = listItem;
                this.listInput = '';
            }
        },
        /**
         * This method deletes a index from the list and clamps it to the length
         * @param {Number} index - The index you wish to delete
         */
        deleteKeywords(index) {
            this.formattedList.splice(index, 1);

            // Clamps the index of the input to list boundaries
            if (index > this.formattedList.length - 1) {
                this.activeKeywordsIndex = this.formattedList.length - 1;
                this.focusOpenInput();
            }
        },
        /**
         * Checks if the current input is active or not
         * @param {Number} index - Used to determine is active or not
         */
        isActive(index) {
            return this.activeKeywordsIndex === index;
        },
        /**
         * Adds onto the list mode with a new input that a user can type into
         */
        onAddToList() {
            this.formattedList.unshift('');
            this.activeKeywordsIndex = 0;
            this.listInput = '';

            this.focusOpenInput();

            this.$emit('onAdd', this.formattedList);
        },
        /**
         * This method enables the list mode and creates a list based of the text area
         * @param {Event} evt - Is used to determine the the text needed to be used
         */
        enableListMode(evt) {
            this.listModeToggled = true;

            if (this.textAreaContent === '' && !this.formattedList.length) {
                this.formattedList.push('');
                this.activeKeywordsIndex = 0;
            } else {
                this.listModeToggled = true;
                this.formattedList = this.formatToList(this.textAreaContent);
            }

            if (!this.activeKeywordsIndex) {
                this.activeKeywordsIndex = 0;
            }
            this.focusOpenInput();
            this.$emit('onListMode', this.listModeToggled);
        },
        /**
         * When used, the method turns from the list mode to the text area original input and joins the list
         */
        disableListMode() {
            this.listModeToggled = false;

            let list = this.formatToTextArea(this.formattedList);
            this.textAreaContent = list;
            this.$emit('onListMode', this.listModeToggled);
        },
        /**
         * A method which handles the down key event on the list and moves
         * the index of the current input list index
         * @param {Number} index - Index of the current position
         * @param {Event} evt - Event caused by the down key on the input
         */
        traverseDown(index, evt) {
            if (
                this.activeKeywordsIndex < this.formattedList.length - 1 &&
                evt.key === 'ArrowDown'
            ) {
                this.activeKeywordsIndex++;
            } else {
                this.activeKeywordsIndex = 0;
            }

            this.traverse(index);
        },
        /**
         * A method which handles the up key event on the list and moves
         * the index of the current input list index
         * @param {Number} index - Index of the current position
         * @param {Event} evt - Event caused by the up key on the input
         */
        traverseUp(index, evt) {
            if (this.activeKeywordsIndex > 0 && evt.key === 'ArrowUp') {
                this.activeKeywordsIndex--;
            } else {
                this.activeKeywordsIndex = this.formattedList.length - 1;
            }

            this.traverse(index);
        },
        /**
         * A helper method that is used to handle the focus and deletion
         * of inputs when the user moves up or down
         * @param {Number} index - This is the index before the move
         */
        traverse(index) {
            if (this.listInput === '' && this.formattedList[index] === '') {
                this.deleteKeywords(index);
                if (this.activeKeywordsIndex > this.formattedList.length - 1) {
                    this.activeKeywordsIndex = this.formattedList.length - 1;
                }
            }

            this.listInput = '';
            this.focusOpenInput();
        },
        /**
         * A method which autocompletes the current selected list input
         * @param {Number} index - The item in the list you wish to override
         */
        autoFill(index) {
            this.listInput = this.formattedList[index];
            this.focusOpenInput();
        },
        /**
         * A method which focuses the input on the currently selected list input
         */
        focusOpenInput() {
            this.$nextTick(() => {
                if (
                    this.$refs.hasOwnProperty(this.activeInputRef) &&
                    this.$refs[this.activeInputRef].length
                ) {
                    this.$refs[this.activeInputRef][0].focus();
                }
            });
        },
        /**
         * Splits a text by separator symbol
         * @param {String} text The posted text.
         * @returns {Array} The text split by separator.
         */
        formatToList(text) {
            if (this.trimmed) {
                let list = text.split(this.separator);
                return list.map((listItem) => listItem.trim());
            } else {
                return text.split(this.separator);
            }
        },
        /**
         * Splits a text by separator symbol
         * @param {String} text The posted text.
         * @returns {Array} The text split by separator.
         */
        formatToTextArea(text) {
            return text.join(this.separator);
        },
    },
};
</script>

<style lang="scss" scoped>
@import 'src/scss/variables.scss';

.keywords-list {
    min-width: 10rem;
    width: 100%;

    ::v-deep .confirm-list-input {
        border-bottom-left-radius: 0;
        border-top-left-radius: 0;
    }

    .list-group-item:first-child {
        padding: 0 !important;
        .util-buttons {
            button {
                flex-grow: 1;
                &:first-child {
                    border-top-right-radius: 0;
                    border-bottom-right-radius: 0;
                    border-bottom-left-radius: 0;
                }
                &:last-child {
                    border-top-left-radius: 0;
                    border-bottom-left-radius: 0;
                    border-bottom-right-radius: 0;
                }
            }
            .add-keywords {
                width: 100%;
                height: 100%;
                padding: 0;
                border-bottom-left-radius: 0;
                border-bottom-right-radius: 0;
            }
        }
    }
    .keywords {
        position: relative;
        padding-right: 2rem;
        min-height: 3rem;
        .clear-btn {
            top: 47.5%;
            transform: translateY(-50%);
            padding: 0;
            right: 0.4rem;
            position: absolute;
            text-decoration: none !important;
            background-color: transparent !important;
            border: none;
            font-size: 1.75rem;
            color: $secondary !important;
        }
    }
}
</style>
