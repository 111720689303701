import { isEmpty } from 'lodash';
import ApiSearch from '@/api/search.js';

export default {
    props: {
        /**
         * Keeps track of the update status of each single node,
         * changes to true when a node has been updated.
         * @name nodeUpdated
         * @type Boolean
         */
        nodeUpdated: {
            type: Boolean,
            default: false,
        },
    },

    data() {
        return {
            /**
             * A flag used to notify if all actions related to a node update have been performed.
             * @name nodeUpdated
             * @type Boolean
             */
            nodeUpdateFinished: false,
        };
    },

    watch: {
        nodeUpdateFinished(finished) {
            if (finished === true) {
                this.$emit('node:updateFinished');
                this.nodeUpdateFinished = false;
            }
        },
    },

    methods: {
        /**
         * Registers event listeners for a single node.
         * All event listeners on a single node should be registered here.
         * @param {Object} inputElement The input element.
         * @param {Object} parentNode The parent node.
         * @returns {undefined|void}
         */
        registerNodeEventListeners(inputElement, parentNode) {
            if (!inputElement) {
                return console.error(
                    'Node element not available, can not register events'
                );
            }

            inputElement.addEventListener('input', (inputEvent) => {
                this.onNodeInput(inputElement, inputEvent);
            });

            inputElement.addEventListener('paste', (pasteEvent) => {
                this.onNodePaste(inputElement, pasteEvent, parentNode);
            });
        },

        //#region events actions registration

        /**
         * Actions triggered by the input event on a single node.
         * All events on input should be called here.
         * @param {Object} inputElement The input element.
         * @param {Event} inputEvent The paste event.
         */
        onNodeInput(inputElement, inputEvent) {
            this.removeErrorCue(inputElement, inputEvent);
        },

        /**
         * Actions triggered by the paste event on a single node.
         * All events on paste should be called here.
         * @param {Object} inputElement The input element we are pasting in.
         * @param {Event} inputEvent The paste event.
         * @param {Object} parentNode The parent node.
         */
        onNodePaste(inputElement, pasteEvent, parentNode) {
            this.bulkAddClassesFromPastEvent(
                inputElement,
                pasteEvent,
                parentNode
            );
        },

        //#endregion events actions registration

        //#region onInput events - Actions triggered on node input

        /**
         * Removes the error cue whenever the field's contents change.
         * @param {Object} inputElement The input element.
         * @param {Event} inputEvent The paste event.
         */
        removeErrorCue(inputElement, inputEvent) {
            inputElement.title = '';
            inputEvent.target.classList.remove('invalid');
        },

        //#endregion onInput events

        //#region onPaste events - Actions triggered when a paste event is detected

        /**
         * Splits multiple pasted rows into array and bulk adds classes to the tree.
         * @param {Object} inputElement The input element we are pasting in.
         * @param {event} pasteEvent The paste event.
         * @param {Object} parentNode The parent node object of the node the input element belongs to.
         * @returns {undefined|void}
         */
        async bulkAddClassesFromPastEvent(
            inputElement,
            pasteEvent,
            parentNode
        ) {
            const pastedText = pasteEvent.clipboardData.getData('text');

            let newClassesLabels = pastedText.split('\n');

            // If only one row is pasted abort
            if (newClassesLabels.length < 2) {
                return;
            }

            // The actual input element is removed.
            inputElement.remove();

            // Clean input, remove duplicate and sort the array top to bottom.
            newClassesLabels = newClassesLabels
                .map((newClassesLabel) => {
                    return newClassesLabel.trim();
                })
                .filter((newClassLabel, index, self) => {
                    return self.indexOf(newClassLabel) === index;
                })
                .reverse();

            // Classes are created
            await this.bulkCreateClasses(newClassesLabels, parentNode);
        },

        /**
         * Given an array of primary labels ddds classes in bulk to the class tree.
         * @param {Array<String>} newClassesLabels The list of new labels.
         * @param {Object} parentNode The parent node object.
         * @returns {Promise} An empty promise when the bulk add is complete and rendered.
         */
        bulkCreateClasses(newClassesLabels, parentNode) {
            return new Promise(async (resolve) => {
                for (const newClassLabel of newClassesLabels) {
                    await this.addSingleClass(newClassLabel, parentNode);
                }

                resolve();
            });
        },

        /**
         * Adds a single class in the bulk add process.
         * @param {String} newClassLabel The new class label.
         * @param {Object} parentNode The parent node object.
         * @returns {Promise} A promise of the DOM update.
         */
        async addSingleClass(newClassLabel, parentNode) {
            if (!newClassLabel || !newClassLabel.trim()) {
                return;
            }

            const parent = parentNode || this.$refs.tree;
            const nextTick = parent.vm ? parent.vm.$nextTick : parent.$nextTick;

            const classesWithSameLabel = await ApiSearch.byLabel(
                this.ontologyID,
                newClassLabel
            );
            const currentlyEditedClassesWithSameLabel =
                this.$refs.tree.find(newClassLabel);

            if (
                classesWithSameLabel.data.length ||
                currentlyEditedClassesWithSameLabel
            ) {
                return;
            }

            const nodeStub = this.createNodeStub(parent, {
                primaryLabel: newClassLabel,
            });

            const newNode = parent.prepend(nodeStub);

            await nextTick();

            this.onNodeLabelChange(newNode, newClassLabel, '');

            return this.awaitNodeUpdate();
        },

        async addBulkClasses(newClassLabels, parentNode) {
            if (!Array.isArray(newClassLabels)) return;
            const newBulkNodeList = [];

            for (const newClassLabel of newClassLabels) {
                const parent = parentNode || this.$refs.tree;

                const classesWithSameLabel = await ApiSearch.byLabel(
                    this.ontologyID,
                    newClassLabel
                );
                const currentlyEditedClassesWithSameLabel =
                    this.$refs.tree.find(newClassLabel);
                if (
                    classesWithSameLabel.data.length ||
                    currentlyEditedClassesWithSameLabel
                ) {
                    continue;
                }

                const nodeStub = this.createNodeStub(parent, {
                    primaryLabel: newClassLabel,
                });

                const newNode = parent.prepend(nodeStub);

                newBulkNodeList.push({
                    newNode,
                    newClassLabel,
                    oldLabel: '',
                });
            }

            this.onNewBulkNodes(newBulkNodeList);

            return this.awaitNodeUpdate();
        },
        //#endregion onPaste events

        //#region onNodeNew events - Actions triggered when a new node is created

        /**
         * Creates a node stub when a tree element is firstly added.
         * @param {Object} parentNode The parent node object.
         * @param {Object} params Overrides new node stub params.
         * @returns {Object} The new node.
         */
        createNodeStub(parentNode, params = {}) {
            const treeComponent = this.$refs.tree;
            const classData = treeComponent.tree.model[0].data;
            const nodeStub = {
                data: {
                    id: this.$uuid.v4() + '-NEW',
                    shortFormIDs: [
                        classData.sourceUniqueID + ':000000' + this.nodeCount,
                    ],
                    primaryID:
                        'http://centree/ontology/ID_000000' + this.nodeCount,
                    primaryLabel: '',
                    sourceUniqueID: classData.sourceUniqueID,
                    entityType: 'ontologyClass',
                    textualDefinitions: [],
                    synonyms: [],
                    partOf: [],
                    derivesFrom: [],
                    developsFrom: [],
                    equivalences: [],
                    typeOfNode: 'subClassOf',
                    numberOfChildren: null,
                    ...params,
                },
            };

            if (!isEmpty(parentNode)) {
                nodeStub.data.superClasses = [parentNode.data.primaryID];
            } else {
                nodeStub.data.superClasses = [''];
            }

            return nodeStub;
        },

        //#endregion onNodeNew events

        //#region Node update status

        /**
         * Watches if nodeUpdates becomes true,
         * resolves the promise when nodeUpdated is true or after 1 second
         * and sets nodeUpdated to false.
         * @returns {Promise<unknown>}
         */
        awaitNodeUpdate() {
            return new Promise((resolve) => {
                const timeoutAt = 30;
                let waited = 0;

                const awaitingNodeUpdated = setInterval(() => {
                    const timedOut = waited >= timeoutAt;
                    waited++;

                    if (this.nodeUpdated || timedOut) {
                        clearInterval(awaitingNodeUpdated);
                        this.nodeUpdateFinished = true;
                        resolve();
                    }
                }, 10);
            });
        },

        //#endregion Node update status
    },
};
