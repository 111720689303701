<template>
    <div
        class="tree-container text-center"
        :class="{
            filtered: treeFilter,
            'filter-partial': isPartialFilter,
            'filter-list': filterDisplay == 'list',
            'filter-tree': filterDisplay == 'tree',
        }">
        <div
            v-if="hasToolbar"
            ref="treeToolbar"
            class="tree-toolbar"
            :class="{ 'grayed-out': !isTreeIdle || !initTreeData.length }"
            @dblclick="$event.stopPropagation()">
            <b-button-toolbar
                class="actions-bar align-items-center justify-content-between"
                horizontal>
                <b-button
                    v-if="isEditable"
                    class="border-0 py-0 mb-1"
                    size="sm"
                    variant="outline-info"
                    @click="onNodeNew()">
                    <font-awesome-icon icon="plus" />
                    Add root class
                </b-button>
                <span v-else>
                    <b-button
                        class="border-0 py-0 mb-1"
                        size="sm"
                        variant="outline-secondary"
                        @click="$emit('tree:collapse')">
                        {{ rootCount }} root {{ 'node' | pluralize(rootCount) }}
                    </b-button>
                    <info-icon
                        v-if="rootCount > maxRootClasses"
                        :help-hint="`Only the first ${maxRootClasses} roots are shown. You may access the other root classes through the class breadcrumb above.`" />
                </span>

                <small class="tree-stats text-muted mb-1">
                    <span class="align-middle"
                        >{{ nodeCount }} nodes fetched</span
                    >
                    <strong v-if="treeFilter">
                        {{ $refs.tree.matches.length }}
                        {{
                            filterDisplay === 'list' ? 'listed' : 'hightlighted'
                        }}
                    </strong>
                    <b-button
                        v-else-if="selectCount"
                        class="border-0 px-1 py-0"
                        size="sm"
                        variant="outline-secondary"
                        @click="mountAll()">
                        {{ selectCount }} selected
                    </b-button>
                </small>

                <b-button
                    class="border-0 py-0 mb-1"
                    size="sm"
                    variant="outline-secondary"
                    :disabled="!isTreeExpandable()"
                    @click="isTreeExpandable() && onExpandAll()">
                    Expand all
                </b-button>
            </b-button-toolbar>
        </div>

        <!-- HIERARCHY -->
        <liquor-tree
            ref="tree"
            class="clearfix text-left"
            v-show="isTreeIdle"
            :key="treeRenderCount"
            :data="initTreeData"
            :options="options"
            :filter="treeFilter"
            @tree:mounted="onTreeMounted"
            @tree:filtered="onFiltered"
            @node:selected="isSelectable && onNodeSelected($event)"
            @node:text:changed="onNodeLabelChange"
            @node:collapsed="onNodeCollapsed"
            @node:dblclick="isSelectable && onNodeDblClick($event)">
            <div
                slot-scope="{ node }"
                class="node-container"
                :class="{
                    'has-relationship': hasRelationship(node),
                    'has-fetched-children': node.numberOfChildren,
                    highlighted: node.data.primaryID === currPrimaryID,
                }"
                :style="{
                    opacity: node.matchedWeight || !treeFilter ? 1 : 0.5,
                }">
                <template v-if="treeFilter && node.matchedWeight">
                    <b-badge
                        v-if="isFilterFuzzy || filterDisplay === 'tree'"
                        variant="light"
                        pill
                        :data-score="matchedScore(node.matchedWeight)">
                        <template v-if="node.matchedWeight < 1">
                            {{ relevance(node.matchedWeight) }}%
                        </template>
                        <font-awesome-icon
                            v-else
                            icon="check"
                            class="text-success align-middle" />
                    </b-badge>
                </template>
                <span
                    :id="node.id"
                    :ref="node.id"
                    class="node-text-wrapper align-middle">
                    <b-link
                        :class="[
                            buildNodeTestId(node.text),
                            'node-text',
                            `${node.data.typeOfNode}-node`,
                            {
                                selectable: isSelectable,
                                'text-info': isNew(node),
                                'obsolete-class': isObsolete(node),
                            },
                        ]"
                        :data-relationship="
                            node.data.typeOfNode &&
                            node.data.typeOfNode.toUpperCase()[0]
                        "
                        :data-children="node.numberOfChildren"
                        :to="nodeLink(node)"
                        :disabled="isSelectable"
                        >{{ node.text }}</b-link
                    >
                    <font-awesome-icon
                        v-if="
                            isEditable &&
                            !isObsolete(node) &&
                            (hasError(node).length || hasEdit(node).length)
                        "
                        icon="circle"
                        class="align-text-top edit-state-hint"
                        :class="{
                            'text-danger': hasError(node).length,
                            'text-info': hasEdit(node).length,
                        }" />
                    <font-awesome-icon
                        v-else-if="
                            isEditable &&
                            !isObsolete(node) &&
                            node.collapsed() &&
                            hasEditedDescendant(node).length
                        "
                        :icon="['far', 'circle']"
                        class="align-text-top edit-state-hint text-info ml-2" />
                </span>
                <b-badge v-if="isMerging(node)" class="ml-2 mr-1"
                    >merging</b-badge
                >
                <template v-if="node.isHover || isHoverOrModalOpen(node)">
                    <span
                        v-if="isEditable && !isObsolete(node)"
                        class="node-controls text-info align-middle">
                        <font-awesome-icon
                            class="secondary-btn edit-btn ml-1"
                            icon="tag"
                            v-b-tooltip.hover="{
                                title: `Change the ${humanReadable(
                                    'primaryLabel'
                                )}`,
                                boundary: 'window',
                                delay: { show: showDelay, hide: 0 },
                            }"
                            @mouseup.stop="onNodeEdit(node)" />
                        <font-awesome-icon
                            class="secondary-btn add-btn ml-1"
                            icon="plus"
                            v-b-tooltip.hover="{
                                title: 'Add a new child to this class',
                                boundary: 'window',
                                delay: { show: showDelay, hide: 0 },
                            }"
                            @mouseup.stop="onNodeNew(node)" />

                        <BulkAddClassesModal
                            :showButton="true"
                            @onToggle="toggleBulkAddModal(node, $event)"
                            @onConfirm="bulkAddClasses(node, $event)"
                            @onCancel="currentBulkModal.isOpen = false" />
                        <MergeClassesModal
                            v-show="showMergeModalButton"
                            :showButton="true"
                            @onToggle="toggleMergeClassesModal(node, $event)"
                            :node-data="node.data" />
                    </span>
                    <span v-else class="node-controls align-middle ml-2">
                        <text-actions
                            slot="actions"
                            :copyTargetName="humanReadable('primaryLabel')"
                            :copyTargetEl="() => $refs[node.id]"
                            :searchTargetName="humanReadable('primaryLabel')"
                            :searchTargetEl="() => $refs[node.id]" />
                    </span>
                    <b-popover
                        placement="bottom"
                        triggers="hover"
                        boundary="window"
                        :target="node.id"
                        :title="node.data.primaryID"
                        :delay="{ show: showDelay, hide: 0 }">
                        <class-summary
                            v-bind="node.data"
                            :isNew="isNew(node)"
                            :isObsolete="isObsolete(node)">
                            <div
                                v-if="
                                    !node.isBatch &&
                                    node.numberOfChildren &&
                                    (!treeFilter || filterDisplay !== 'list')
                                "
                                class="summary-children">
                                <strong>Children</strong>:
                                {{ node.numberOfChildren }} class
                                {{ 'node' | pluralize(node.numberOfChildren) }}
                                in total.
                            </div>
                        </class-summary>
                    </b-popover>
                </template>
                <template v-if="treeFilter && filterDisplay === 'list'">
                    <span
                        v-if="node.duplicates && node.duplicates.length > 1"
                        class="float-right">
                        <font-awesome-icon
                            class="prev-path"
                            icon="chevron-circle-left"
                            size="sm"
                            @mouseup.stop="onPathNav(node, false)" />
                        <span class="text-muted"
                            >{{ node.dupIndex + 1 }} of
                            {{ node.duplicates.length }} paths</span
                        >
                        <font-awesome-icon
                            class="next-path"
                            icon="chevron-circle-right"
                            size="sm"
                            @mouseup.stop="onPathNav(node)" />
                    </span>
                    <span v-else class="float-right text-muted">1 path</span>
                    <b-carousel
                        class="border-top mt-1 pt-1"
                        :interval="0"
                        v-model="node.dupIndex">
                        <b-carousel-slide
                            v-for="(dupNode, index) in node.duplicates"
                            :key="index">
                            <div slot="img" class="text-secondary">
                                <span class="text-body soft-bold"
                                    >Ancestors</span
                                >:
                                <template v-if="nodePath(dupNode).length">
                                    <span
                                        v-for="ancestor in nodePath(dupNode)"
                                        class="dupnode-container"
                                        :class="{
                                            'has-relationship':
                                                hasRelationship(ancestor),
                                        }"
                                        :key="`ancestor-${index}-${ancestor.data.id}-${node.id}-${global_randomID}`">
                                        <b-link
                                            :class="`pr-1 dupnode-text ${ancestor.data.typeOfNode}-node`"
                                            :id="`ancestor-${index}-${ancestor.data.id}-${node.id}-${global_randomID}`"
                                            :data-relationship="
                                                ancestor.data.typeOfNode.toUpperCase()[0]
                                            "
                                            :to="{
                                                name: 'class',
                                                params: {
                                                    ontologyID:
                                                        ancestor.data
                                                            .sourceUniqueID,
                                                    primaryID:
                                                        ancestor.data.primaryID,
                                                },
                                            }">
                                            {{ ancestor.text }}
                                        </b-link>
                                        <b-popover
                                            placement="bottom"
                                            triggers="hover"
                                            boundary="window"
                                            :target="`ancestor-${index}-${ancestor.data.id}-${node.id}-${global_randomID}`"
                                            :title="ancestor.data.primaryID"
                                            :delay="{
                                                show: showDelay,
                                                hide: 0,
                                            }">
                                            <class-summary
                                                v-bind="ancestor.data"
                                                :isNew="isNew(ancestor)"
                                                :isObsolete="
                                                    isObsolete(ancestor)
                                                " />
                                        </b-popover>
                                        <font-awesome-icon
                                            v-if="!ancestor.isRoot()"
                                            class="text-muted mr-2"
                                            icon="chevron-left" />
                                    </span>
                                </template>
                                <span v-else class="text-muted">none</span>
                            </div>
                        </b-carousel-slide>
                    </b-carousel>
                </template>
            </div>
        </liquor-tree>

        <!-- LOADING FEEDBACK -->
        <div class="tree-loading" v-if="!isTreeIdle">
            <h4 class="loading"></h4>
            <p>Please wait while the tree is being updated</p>
        </div>

        <!-- EMPTY FILTERED RESULTS -->
        <div
            class="tree-no-results"
            v-else-if="treeFilter && $refs.tree.matches.length == 0">
            <h4 class="text-muted">No class nodes found</h4>
            <p class="m-0">
                Please try a less restrictive filter or
                <b-button
                    class="border-0"
                    size="sm"
                    variant="outline-secondary"
                    v-b-tooltip.hover="{
                        title: 'Grows the tree in depth by adding more child classes',
                        delay: { show: showDelay, hide: 0 },
                    }"
                    @click="
                        isTreeIdle = false;
                        grow('all', true);
                    ">
                    <svg
                        class="fetch-icon svg-icon"
                        viewBox="0 0 1024 1024"
                        xmlns="http://www.w3.org/2000/svg">
                        <path
                            d="M531.2 609.28l-70.4 70.4V458.24a30.72 30.72 0 0 0-61.44 0v222.08l-70.4-70.4a30.528 30.528 0 0 0-43.52 0c-12.16 12.16-12.16 31.36 0 43.52l122.88 122.88c5.76 5.76 14.08 8.96 21.76 8.96 7.68 0 16-3.2 21.76-8.96l122.88-122.88c12.16-12.16 12.16-31.36 0-43.52-12.16-12.8-31.36-12.8-43.52-.64zm328.96 46.08c0 22.4-18.56 40.96-40.96 40.96h-61.44V368.64c0-56.32-46.08-102.4-102.4-102.4H327.68V204.8c0-22.4 18.56-40.96 40.96-40.96H819.2c22.4 0 40.96 18.56 40.96 40.96v450.56zM696.32 819.2c0 22.4-18.56 40.96-40.96 40.96H204.8c-22.4 0-40.96-18.56-40.96-40.96V368.64c0-22.4 18.56-40.96 40.96-40.96h450.56c22.4 0 40.96 18.56 40.96 40.96V819.2zM819.2 102.4H368.64c-56.32 0-102.4 46.08-102.4 102.4v61.44H204.8c-56.32 0-102.4 46.08-102.4 102.4V819.2c0 56.32 46.08 102.4 102.4 102.4h450.56c56.32 0 102.4-46.08 102.4-102.4v-61.44h61.44c56.32 0 102.4-46.08 102.4-102.4V204.8c0-56.32-46.08-102.4-102.4-102.4z" />
                    </svg>
                    fetch more nodes
                </b-button>
            </p>
        </div>
    </div>
</template>

<script>
import {
    debounce,
    difference,
    extend,
    flatMapDeep,
    intersection,
    isEmpty,
    lowerFirst,
    map,
    omit,
    orderBy,
    pick,
    pull,
    slice,
    sortBy,
    uniqWith,
    without,
} from 'lodash';
import FuzzySet from 'fuzzyset.js';
import LiquorTree from 'liquor-tree';
import asyncPool from 'tiny-async-pool';

import InfoIcon from '@/components/ui/InfoIcon';
import TextActions from '@/components/ui/TextActions';
import ClassSummary from '@/components/ui/ClassSummary';
import MergeClassesModal from '@/components/ui/HierarchyTree/MergeClassesModal';
import BulkAddClassesModal from '@/components/ui/HierarchyTree/BulkAddClassesModal.vue';

import ApiOntology from '@/api/ontology';

import singleNodeMixin from '@/mixins/components/ui/HierarchyTree/singleNodeMixin.js';
import componentInjector from '@/mixins/componentInjector';
import { HELP_HINTS, RELATIONSHIP_PROPS } from '@/config';
import { getRouterHash, propValuesDifference } from '@/utils';
import {
    useClassTree,
    useClassView,
    useEdits,
    usePropertyTree,
} from '@/compositions';

export default {
    name: 'HierarchyTree',
    components: {
        LiquorTree,
        InfoIcon,
        TextActions,
        ClassSummary,
        BulkAddClassesModal,
        MergeClassesModal,
    },

    props: {
        // data for each of the would-be tree nodes
        // NOTE: this data is as is from the API and, therefore, has none of the required expansion presets
        nodes: {
            type: Object,
            required: true,
        },

        currPrimaryID: {
            type: String,
            default: '',
        },

        nodeChildrenMap: {
            type: String,
            default: 'leaves',
        },

        nodeDataMap: {
            type: String,
            default: 'value',
        },

        initFilterQuery: {
            type: String,
            default: '',
        },

        initFilterFuzzy: {
            type: Boolean,
            default: true,
        },

        initFilterDisplay: {
            type: String,
            default: 'tree',
        },

        // Mappings are a special relationship property that should not trigger sandboxing.
        relationshipProps: {
            type: Array,
            default: function () {
                return without(RELATIONSHIP_PROPS, 'mappings');
            },
        },

        // Translation map from typeOfNode to relationship name
        typeOfRelMap: {
            type: Object,
            default: function () {
                return {
                    equivalence: 'equivalences',
                    subClassOf: 'superClasses',
                };
            },
        },

        // Class properties enabled by default in the filter.
        initSelFilterProps: {
            type: Array,
            default: function () {
                return ['synonyms', 'shortFormIDs', 'primaryLabel'];
            },
        },

        // Exclude the following from the list of class properties the user is allowed to filter by.
        filterBlacklist: {
            type: Array,
            default: function () {
                return [
                    'superClasses',
                    'typeOfNode',
                    'id',
                    'numberOfChildren',
                    'entityUniqueID',
                    'entityType',
                    'shortDisplayName',
                    'sourceUniqueID',
                    'developsFrom',
                    'derivesFrom',
                    'equivalences',
                    'partOf',
                    'annotationProperties',
                    'relationalProperties',
                    'version',
                    'mappings',
                ];
            },
        },

        // Properties that are not the same across nodes representing the same class or that are dynamic will not synced.
        syncBlacklist: {
            type: Array,
            default: function () {
                return ['typeOfNode', 'text'];
            },
        },

        editLog: {
            type: Object,
            default: function () {
                return {};
            },
        },

        // Default number of nodes that can be mounted automatically (forcing expansion) concurrently.
        defMountLimit: {
            type: Number,
            default: 4,
        },

        isSelectedOnRender: {
            type: Boolean,
            default: true,
        },

        isEditable: {
            type: Boolean,
            default: false,
        },

        // Mouse interaction with individual nodes is allowed
        isSelectable: {
            type: Boolean,
            default: true,
        },

        isPresetNodes: {
            type: Boolean,
            default: true,
        },

        hasToolbar: {
            type: Boolean,
            default: false,
        },

        isRouterHash: {
            type: Boolean,
            default: getRouterHash(),
        },

        typeDelay: {
            type: Number,
            default: parseInt(process.env.VUE_APP_TYPE_DEBOUNCE),
        },

        concurrencyMax: {
            type: Number,
            default: parseInt(process.env.VUE_APP_CONCURRENCY_MAX),
        },

        childrenPageSize: {
            type: Number,
            default: parseInt(process.env.VUE_APP_CHILDREN_SIZE),
        },

        maxRootClasses: {
            type: Number,
            default: parseInt(process.env.VUE_APP_ROOT_SIZE),
        },

        showDelay: {
            type: Number,
            default: parseInt(process.env.VUE_APP_SHOW_DELAY),
        },

        helpHints: {
            type: Object,
            default: function () {
                return HELP_HINTS;
            },
        },

        pageNumberOffset: {
            type: Number,
            default: 0,
        },

        ontologyID: {
            type: String,
            required: true,
        },
    },

    data() {
        return {
            /* TREE PLUGIN CONFIG */
            options: {
                // hides checkboxes
                checkbox: false,

                // prevents the node expanding when clicking on its name
                parentSelect: true,

                // shows the spinner while fetching children
                minFetchDelay: 1,

                // method used to get and transform child nodes from the server
                fetchData: this.fetchChildren,

                propertyNames: {
                    children: this.nodeChildrenMap,
                    data: this.nodeDataMap,
                },

                filter: {
                    emptyText: '',
                    plainList: this.initFilterDisplay === 'list',
                    matcher: this.fuzzyMatcher,
                },
            },

            /* FILTER CONFIG */
            // some filter dropdown is on display
            isFilterPropsShown: false,
            isFilterSettingsShown: false,

            // true if new nodes have been added but not filtered
            isPartialFilter: false,

            // filter query passed into tree component
            treeFilter: '',

            // instant copy of the filter query typed in
            filterQuery: this.initFilterQuery,

            // true if fuzzy logic is applied while filtering
            isFilterFuzzy: this.initFilterFuzzy,

            // display mode of filtered tree
            filterDisplay: this.initFilterDisplay,

            // list of property names selected for filtering
            selFilterProps: this.initSelFilterProps,

            // list of visible property names for every node used as filtering criteria
            filterProperties: [],

            /* TREE STATE */
            // true if there are no collapsed classes visible
            isTreeExpanded: false,

            // true if there are no tree operations in progress
            isTreeIdle: false,

            // true if the tree nodes are being rendered right after mounting the tree root only
            isTreeFirstRender: false,

            // number of root nodes rendered,
            rootRenderCount: 0,

            // forces a tree render update
            // NOTE: the tree plugin's data property is non-reactive, so re-renders have to be forced manually
            // https://github.com/amsik/liquor-tree/issues/4

            // number of class nodes retrieved so far
            nodeCount: 0,

            // number of instances found for the selected class
            selectCount: 0,

            // number of root classes
            rootCount: 0,

            // data with presets ready to be shown as a tree.
            // NOTE: unless new nodes are passed, this property remains unaltered even if the tree changes.
            initTreeData: this.nodes,

            InputModal: {},

            currentBulkModal: {},

            classMergeModal: {},
            nodeProto: {},
        };
    },

    watch: {
        // Re-renders the tree whenever the filter settings are changed.
        filterDisplay(newValue) {
            const isPlainList = newValue === 'list';

            if (this.treeFilter) {
                this.isTreeIdle = false;
                setTimeout(() => {
                    this.$refs.tree.tree.options.filter.plainList = isPlainList;
                    this.$refs.tree.tree.filter(this.treeFilter);
                }, 500);
            } else {
                this.$refs.tree.tree.options.filter.plainList = isPlainList;
            }
        },
        isFilterFuzzy() {
            this.refreshFilter();
        },
        selFilterProps() {
            this.refreshFilter();
        },

        // Re-renders the tree when the provided node data changes.
        nodes: {
            deep: true,
            handler(newValue, oldValue) {
                if (this.pageNumberOffset >= process.env.VUE_APP_ROOT_SIZE) {
                    this.parsePaginatedRoots(this.pageNumberOffset);
                } else {
                    this.parseRoots();

                    this.resetData();
                }
            },
        },

        // Automatically transitions any node being edited when coming out of edition mode.
        isEditable(isOn) {
            if (!isOn) {
                const activeNode = this.$refs.tree.tree.activeElement;

                if (activeNode && activeNode.isEditing) {
                    activeNode.stopEditing();
                }
            }
        },

        // Deselects if no primary ID passed in
        currPrimaryID(newValue) {
            if (!newValue && this.$refs.tree.tree.selectedNodes) {
                this.$refs.tree.tree.unselectAll();
                this.selectCount = 0;
            }
        },

        'nodes.total'(total) {
            this.rootCount = total;
        },
        'nodes.leaves.length'(totalLeaves) {
            if (totalLeaves >= this.nodes.total) {
                const moreBtnEl = document.getElementById('rootMoreBtn');
                if (moreBtnEl) {
                    moreBtnEl.remove();
                }
            }
        },
    },

    created() {
        usePropertyTree().setHasBeenOnClassTree(true);
        if (usePropertyTree().getHasBeenOnPropertyTree.value) {
            usePropertyTree().setHasBeenOnPropertyTree(false);
            window.location.reload();
        }
        this.onPathNav = debounce(this.onPathNav, this.typeDelay);
        this.parseRoots();
    },

    mounted() {
        this.setNodeProto(this.$refs.tree);
    },

    // Modifies the default tree plugin's behaviour for certain node operations.
    beforeDestroy() {
        useClassTree().reSetRenderCount();
    },

    computed: {
        editedClasses() {
            return useEdits().getClassesWithEdits.value;
        },
        showMergeModalButton() {
            return (
                !useEdits().countEditedClasses.value &&
                !useEdits().hasMergeEditChanges.value
            );
        },
        treeRenderCount() {
            return useClassTree().getRenderCount.value;
        },
    },

    methods: {
        /**
         * Normalises the root node data so that it complies with what the tree plugin expects.
         * It also harvests the node property names for the filter logic.
         * NOTE: A global parent class is assummed from the API, even for ontology root classes.
         */
        parseRoots() {
            let rootChildren;
            // Child nodes returned as expected
            if (
                this.nodes.hasOwnProperty(this.nodeChildrenMap) &&
                this.nodes[this.nodeChildrenMap].length
            ) {
                rootChildren = this.nodes[this.nodeChildrenMap];
                this.filterProperties = sortBy(
                    pull(Object.keys(rootChildren[0]), ...this.filterBlacklist)
                );

                // Empty tree => makes sure all loading feedback is stopped.
            } else {
                rootChildren = [];
                if (this.nodes.hasOwnProperty(this.nodeChildrenMap)) {
                    setTimeout(() => {
                        this.isTreeIdle = true;
                    }, 0);
                }
            }

            this.initTreeData = rootChildren;
        },

        /**
         * Normalises the root node data so that it complies with what the tree plugin expects.
         * It also harvests the node property names for the filter logic.
         * @see {@link parseRoots} -  This is a modified version of parseRoots to support root nodes
         * NOTE: A global parent class is assummed from the API, even for ontology root classes.
         * @param pageOffset - specified index to start the new page from, ie 20, 40, 60, etc.
         */
        parsePaginatedRoots(pageOffset) {
            if (pageOffset >= 0) {
                let rootChildren;

                // Child nodes returned as expected
                if (
                    this.nodes.hasOwnProperty(this.nodeChildrenMap) &&
                    this.nodes[this.nodeChildrenMap].length
                ) {
                    rootChildren = this.nodes[this.nodeChildrenMap];
                    this.filterProperties = sortBy(
                        pull(
                            Object.keys(rootChildren[0][this.nodeDataMap]),
                            ...this.filterBlacklist
                        )
                    );
                }
                // Uses the tree parse function to turn our newly paginated objects into nodes that fit the liqour tree

                const nodes = this.$refs.tree.tree.parse(
                    slice(rootChildren, pageOffset),
                    this.$refs.tree.options.modelParse
                );

                /**
                 * Add each node that has been parsed to the tree.
                 * Note needs to wait for next tick for extra formatting to apply to the node to fit the tree model.
                 * Load children then fetches any children found from the api.
                 **/
                nodes.forEach((node) => {
                    this.$refs.tree.tree.addToModel(node);
                    this.$nextTick(() => {
                        this.$refs.tree.tree.loadChildren(node);
                    });
                });

                // Makes the load more button enabled again
                const moreBtnEl =
                    this.$refs.tree.$el.getElementsByClassName('more-btn')[0];
                if (moreBtnEl) {
                    moreBtnEl.disabled = false;
                } else {
                    const moreButtonEl = document.createElement('button');
                    this.$refs.tree.$el.appendChild(moreButtonEl);
                    moreButtonEl.className =
                        'more-btn btn btn-outline-secondary btn-sm mb-1 ml-4';
                    moreButtonEl.title = `Adds next ${process.env.VUE_APP_ROOT_SIZE} class nodes`;
                    moreButtonEl.innerText = 'Load More';

                    moreButtonEl.addEventListener('click', () => {
                        moreButtonEl.disabled = true;
                        this.$emit('tree:paginate');
                    });

                    if (this.rootCount <= 20) {
                        moreButtonEl.disabled = true;
                    }
                }
            }
        },

        /**
         * Retrieves all the children for a given parent node in the tree. During the process, it gives
         * each child the expected node format for subsequent parsing.
         * NOTE: Child nodes are returned from the API with a different structure than that of the initial path from root.
         * @param {Object} node - Data object for the tree node in question.
         */
        fetchChildren(node) {
            node.isBatchingChildren = true;
            return ApiOntology.children({
                ontologyID: node.data.entityUniqueID,
                classID: node.data.id,
                pageStart: node.currPage * this.childrenPageSize,
            }).then((response) => {
                const fetchedChildren = this.onChildrenBeforeParse(
                    node,
                    response.data.elements,
                    response.data.total
                );

                return fetchedChildren;
            });
        },

        /**
         * Determines if the tree has any visible collapsed nodes with children.
         * NOTE: Children under a collapsed parent may be collapsed themselves. Hence, the "visibility" distinction.
         * @returns True if at least one parent is collapsed.
         */
        hasCollapsed() {
            let isCollapsedFound = false;

            this.$refs.tree.recurseDown((node) => {
                let isCollapsed;

                // When the filter is on in tree mode, it uses the shadow copy of the "expand" property instead.
                if (
                    this.filterQuery &&
                    this.filterDisplay === 'tree' &&
                    this.$refs.tree.matches.length
                ) {
                    isCollapsed = node.__expanded === false;
                } else {
                    isCollapsed = node.collapsed();
                }

                // Prunes all recursive branches as soon as the first top-most collapsed node is found (which must always be visible).
                // NOTE: the tree API's "hasChildren" returns true if the node is batchable.
                if (!isCollapsedFound && isCollapsed && node.hasChildren()) {
                    isCollapsedFound = true;
                }
                return !isCollapsedFound;
            });

            return isCollapsedFound;
        },

        isTreeExpandable() {
            if (this.treeFilter) {
                return (
                    this.filterDisplay === 'tree' &&
                    this.$refs.tree.matches.length &&
                    !this.isTreeExpanded
                );
            } else {
                return !this.isTreeExpanded;
            }
        },

        /**
         * Restores the visibility of any hidden class node.
         * @see {@link onFiltered}
         */
        unhideNodes() {
            const hiddenNodes =
                this.$refs.tree.findAll({ state: { visible: false } }) || [];
            hiddenNodes.forEach((node) => node.show());
        },

        /**
         * Probes all the class node's data properties when filtering, assigning an average matching weight
         * useful for subsequent sorting. The fuzzy mode actually combines with exact matching.
         * @param {String} query - String expected to be contained in any of the node's properties.
         * @param {Object} node - Tree node object.
         * @see {@link https://github.com/Glench/fuzzyset.js#methods}
         */
        fuzzyMatcher(query, node) {
            let targetNodeProps = this.selFilterProps;

            // If no properties are selected, default to using all visible properties.
            if (!targetNodeProps.length) {
                targetNodeProps = this.filterProperties;
            }

            // NOTE: some nodes may have a primaryID set to null. Also, some values are arrays themselves, hence the flattening.
            const flatNode = flatMapDeep(
                pick(node.data, targetNodeProps)
            ).filter(Boolean);
            let results;

            if (this.isFilterFuzzy) {
                // Required minimum score of 0.25 to make it into results. Also, results set defaults to [0, ''] if none found.
                results = FuzzySet(flatNode, false).get(query, [[0, '']], 0.25);

                // Assigns full score if the query is a subset of any property string and flattens the array to just the scores.
                results = results.map((result) => {
                    if (
                        result[0] < 1 &&
                        result[1].toLowerCase().indexOf(query.toLowerCase()) !==
                            -1
                    ) {
                        result[0] = 1;
                    }
                    return result[0];
                });
            } else {
                results = flatNode.map(
                    (propValue) =>
                        propValue.toLowerCase() === query.toLowerCase()
                );
            }

            // Normalises the output of FuzzySet to a single maximum matching weight
            // NOTE: there could be cases where the result set is empty.
            this.$set(
                node,
                'matchedWeight',
                results.length && (Math.max(...results) || 0)
            );
            return node.matchedWeight;
        },

        /**
         * Score indicating the reliability of a given matched node. It assumes three groups of distinctive enough reliance.
         * @param {number} matchedWeight - Fractional distance weight for the node derived after the fuzzy match is applied.
         * @see {@link fuzzyMatcher}
         */
        matchedScore(matchedWeight) {
            const relevance = this.relevance(matchedWeight);

            if (relevance < 50) {
                return 2;
            } else {
                return 3;
            }
        },

        relevance(weight) {
            return Math.round(weight * 100);
        },

        onFilterFocus({ currentTarget }, isFocus) {
            currentTarget.classList.toggle('focus', isFocus);
        },

        /**
         * Ensures the state of the tree is consistent at all times after mounting.
         * NOTE: all tree nodes are wrapped by a div tag, the tree root component. This is the only thing rendered at this point.
         * NOTE: the plugin's recursive data parsing and subsequent model setting happens on the tree's root component mount.
         * Whithin that component, given that the root node and its children are not rendered until there is a model,
         * no child nodes will have been rendered yet when this method is called.
         * @see {@link https://github.com/amsik/liquor-tree/blob/c08d5405ca6a085d582201f651f2e7d75b44f7c6/src/lib/Tree.js}
         * @see {@link onAfterExpand}
         * @see {@link onRootRendered}
         */
        onTreeMounted() {
            if (this.$refs.tree) {
                // Sets up pagination for the tree
                this.setPagination(this.$refs.tree);

                // Expanded state evaluated every time a node is expanded or collapsed individually or after a series of them are.
                this.$on('tree:rendered', this.onExpandStateChange);
                this.$refs.tree.$on(
                    [
                        'node:expanded',
                        'node:collapsed',
                        'tree:expandedAll',
                        'tree:data:received',
                    ],
                    this.onExpandStateChange
                );
                this.$refs.tree.$on(
                    'node:expanded',
                    this.onAfterExpand.bind(this, true)
                );

                this.$refs.tree.$on(
                    'tree:expandedAll',
                    this.onAfterExpand.bind(this, false)
                );

                // Reacts to node changes in the tree.
                this.$refs.tree.$on('node:added', this.onNodeAdded);
                this.$refs.tree.$on('node:deleted', this.onNodeDeleted);
                this.$refs.tree.$on(
                    'tree:data:received',
                    this.onChildrenAfterParse
                );

                // Augments node logic to support model changes on render/update.
                this.setNodeHooks(this.$refs.tree);
            }
        },

        setPagination(treeComponent) {
            if (!this.nodes || !this.nodes.total) return;
            if (this.nodes.leaves.length >= this.nodes.total) return;

            // Add a load more button
            this.$nextTick(() => {
                const moreButtonEl = document.createElement('button');
                treeComponent.$el.appendChild(moreButtonEl);
                moreButtonEl.className =
                    'more-btn btn btn-outline-secondary btn-sm mb-1 ml-4';
                moreButtonEl.id = 'rootMoreBtn';
                moreButtonEl.title = `Adds next ${process.env.VUE_APP_ROOT_SIZE} class nodes`;
                moreButtonEl.innerText = 'Load More';

                moreButtonEl.addEventListener('click', () => {
                    this.$emit('tree:paginate');
                });
            });
        },

        /**
         * Modifies the prototype chain for the node class that includes all node operations. This is to guarantee that those
         * changed operations are performed even if the node has not been rendered yet.
         * TODO: methods are being overshadowed rather than overwritten. This should not be the case as is leading to multiple
         * function calls with past contexts.
         * @param {Object} treeComponent - Vue object for the plugin's tree component.
         */
        setNodeProto(treeComponent) {
            const nodeProto = Object.getPrototypeOf(
                treeComponent.tree.objectToNode({ name: 'stub' })
            );

            // Prevents selection if mouse interaction has been not allowed through this component's options.
            const oldSelect = nodeProto.select;
            nodeProto.select = function (extendList) {
                if (this.tree.vm.$parent.isSelectable) {
                    const selected = oldSelect.call(this, extendList);
                    return selected;
                } else {
                    return this;
                }
            };

            // Avoids custom properties being ignored while cloning.
            // NOTE:  the cloned node is collapsed to defer reconstruction of those custom properties down the children until expansion.
            const oldClone = nodeProto.clone;
            nodeProto.clone = function (isCloneCustom = true) {
                const cloned = oldClone.call(this);

                // Should non-data-derived properties be cloned as well?
                if (isCloneCustom) {
                    extend(
                        cloned,
                        pick(this, [
                            'isBatch',
                            'currPage',
                            'isBatchingChildren',
                            'isCreatedBefore',
                            'numberOfChildren',
                        ])
                    );
                }

                // Certain property values will be determined later at rendering or expansion time. Sets them to defaults here.
                cloned.collapse();
                cloned.unselect();

                return cloned;
            };

            // Forces the emission of the "node:added" event even if the node is batchable.
            // NOTE: the tree plugin does not emit an event in that case.
            const oldInsertAt = nodeProto.insertAt;
            nodeProto.insertAt = function (
                newNode,
                index = this.children.length
            ) {
                const parsedNode = oldInsertAt.call(this, newNode, index);

                if (!Array.isArray(newNode)) {
                    if (this.isBatch) {
                        this.tree.vm.$emit('node:added', this, parsedNode);
                    }
                }

                return parsedNode;
            };

            // Emits "node:deleted" event when any of this nodes's children is removed, sending the original parent as part of the event.
            // NOTE: any reference to the parent node is lost at this stage if the plugin is left to its own devices.
            // NOTE: the plugin does not remove any descendant nodes from the selection list, just the topmost parent. This is fixed here.
            nodeProto.remove = function () {
                const parentNode = this.parent;
                const emitFn = () => {
                    this.tree.vm.$emit('node:deleted', parentNode, this);
                };

                this.tree.removeNode(this);

                this.recurseDown((descendant) => {
                    if (this.tree.selectedNodes.indexOf(descendant) !== -1) {
                        this.tree.selectedNodes.remove(descendant);
                    }
                });

                // Ensures the event is emmited after DOM removal if node still mounted.
                if (this.vm) {
                    this.vm.$nextTick(emitFn);
                } else {
                    emitFn();
                }
            };

            // Creates a new promise used for tracking mount completion, retaining the resolve handler.
            // NOTE: native promises cannot be resolved from outside unless a pointer to the resolver is saved.
            nodeProto.resolveMount = function () {};
            nodeProto.mountPromise = function () {
                return new Promise((resolve, reject) => {
                    this.resolveMount = resolve;
                });
            };

            // Slightly updates the plugin's default handler for edits to strip whitespaces from the new node label.
            // NOTE: by default, changes consisting of just whitespaces are not rejected. Also, "newText" can be false.
            nodeProto.stopEditing = async function (newText) {
                if (!this.isEditing) {
                    return;
                }

                let isChange;

                this.isEditing = false;
                this.tree._editingNode = null;
                this.tree.activeElement = null;

                if (typeof newText !== 'undefined' && newText !== false) {
                    newText = newText.trim();
                    isChange = this.text !== newText;
                } else {
                    isChange = false;
                }

                if (newText && isChange) {
                    this.text = newText;
                }

                this.$emit('editing:stop', isChange);
            };
        },

        /**
         * Performs operations meant to be done right after creation/rendering or updating a node. Since the root node
         * is the last one to be rendered, it also waits till that point to notify the outside world that
         * the tree is guaranteed to have been fully rendered (not just the wrapping tree root component).
         * NOTE: child nodes are destroyed on their parent's collapse and created + mounted on parent expansion.
         * NOTE: handlers that use the "mounted" hook will be called on inverse order on first render.
         * @param {Object} treeComponent - Vue object for the plugin's tree component.
         */
        setNodeHooks(treeComponent) {
            const nodeComponent = treeComponent.$options.components.TreeNode;

            this.isTreeFirstRender = true;

            nodeComponent.created = function () {
                const thisComponent = this.node.tree.vm.$parent;

                if (typeof thisComponent.onNodeCreated !== 'function') return;
                // Least restrictive: every time the node is created.
                thisComponent.onNodeCreated(this.node);

                // Whenever the node is created for the first time (eg: on parent's first expansion...)
                if (!this.node.isCreatedBefore) {
                    thisComponent.onNodeFirstCreated(this.node);
                    this.node['isCreatedBefore'] = true;
                }

                // Most restrictive: only right after tree mount.
                if (
                    thisComponent.isTreeFirstRender &&
                    thisComponent.isPresetNodes
                ) {
                    thisComponent.onNodePreset(this.node);
                }

                // Detects any data changes performed local to the node and propagates them.
                this.node.unwatchData = this.$watch(
                    'node.data',
                    function (newData, oldData) {
                        thisComponent.onNodeDataUpdated(
                            this.node,
                            newData,
                            oldData
                        );
                    },
                    { deep: true }
                );

                // Detects when a node becomes selectable for the first time.
                this.node.unwatchSelectibility = this.$watch(
                    'node.states',
                    function (newData, oldData) {
                        if (this.node.states.selectable) {
                            thisComponent.onNodeBecomesSelectable(
                                this.node,
                                newData,
                                oldData
                            );
                            this.node.unwatchSelectibility();
                        }
                    },
                    { deep: true }
                );

                // Reacts to changes in relationships.
                this.node.unwatchRelationships = this.$watchAll(
                    thisComponent.relationshipProps.map(
                        (propName) => 'node.data.' + propName
                    ),
                    function (relName, newParents, oldParents) {
                        // NOTE: This method is called for every mounted node representing the same class whose relationship
                        // has been changed. This includes mounted nodes that represent a different relationship than the one
                        // changed, due to data change propagation. The check below prevents unwarranted event storms.
                        if (!this.node.isUpdateDetected) {
                            thisComponent.onNodeRelUpdated(
                                this.node,
                                relName.replace('node.data.', ''),
                                newParents,
                                oldParents
                            );
                        }
                    }
                );
            };

            nodeComponent.mounted = function () {
                const thisComponent = this.node.tree.vm.$parent;
                if (!thisComponent || !thisComponent.initTreeData) return;

                if (this.node.isRoot()) {
                    thisComponent.isTreeIdle = true;
                    thisComponent.isTreeFirstRender = false;
                    thisComponent.rootRenderCount++;
                    if (
                        thisComponent.rootRenderCount ===
                        thisComponent.initTreeData.length
                    ) {
                        thisComponent.onRootRendered(this.node);
                    }
                }

                thisComponent.onNodeMounted(this.node);
                thisComponent.onNodeChanged(this.node);

                // Notifies the outside world that a Vue component is available for the node and mounted.
                this.node.isMounted = true;
                this.node.resolveMount();
            };

            nodeComponent.updated = function () {
                const thisComponent = this.node.tree.vm.$parent;
                thisComponent.onNodeChanged(this.node);
            };

            // Internal housekeeping associated with the above modified hooks.
            nodeComponent.beforeUnmounted = function () {
                this.node.isMounted = false;
                this.node.unwatchData();
                this.node.unwatchRelationships.forEach((unwatch) => unwatch());
            };
        },

        /**
         * Given a set of nodes, it guarantees that each are visible by expanding all its respective ancestors. It uses the promise
         * system in place to signal when all of them are indeed in the DOM and rendered.
         * @param {Object[]} nodes - Set of nodes to be mounted. If none provided, the current set of selected nodes is used.
         * @param {Number} mountLimit - Number of nodes to be mounted concurrently.
         */
        mountAll(nodes = [], mountLimit = this.defMountLimit) {
            let allMounted = Promise.resolve();
            let unmounted;

            if (nodes.length) {
                unmounted = nodes.filter((node) => !node.isMounted);
            } else {
                unmounted = this.sameClassNodes(
                    this.$refs.tree.tree.primeSelector,
                    true,
                    true
                );
            }

            // There are nodes to mount => expands the tree up from each and updates tree expandability after being done with all.
            if (unmounted.length) {
                allMounted = asyncPool(mountLimit, unmounted, (node) => {
                    const promise = node.mountPromise();
                    node.expandTop(true);
                    return promise;
                });

                allMounted.then(() => {
                    this.onExpandStateChange();
                });
            }

            return allMounted;
        },

        /**
         * Fetches all the children of all nodes that are batchable but haven't been expanded yet. This has the
         * effect of quietly adding more nodes to the tree without changing its current structure (because no expansion occurs).
         * NOTE: When notifications/confirmations are disabled, the tree won't be grown if there are too many children.
         * @param {Object[] | string} [batchableNodes = []] - List of nodes to be silently expanded. If 'all' specified, it looks for
         * them across the tree.
         * @param {boolean} isNotification - True if the user should be notified of any node additions and be sought confirmation from.
         */
        grow(batchableNodes = [], isNotification = false) {
            const treeComponent = this.$refs.tree;
            let uncreatedNodes;
            let numChildren = 0;

            if (batchableNodes === 'all') {
                batchableNodes = treeComponent.findAll(
                    (node) =>
                        (!node.isCreatedBefore || node.isBatch) &&
                        !node.children.length
                );
                batchableNodes = Array.from(batchableNodes || []);
            }

            // NOTE: nodes may be available but not been rendered yet and, probably, not pre-set either. To allow growth, their
            // batch and page statuses are set in advance.
            uncreatedNodes = batchableNodes.filter((node) => {
                return !node.isCreatedBefore;
            });
            uncreatedNodes.forEach((node) => {
                node.isBatch = true;
                node['currPage'] = 0;
            });

            // Callback for confirmed action
            const onConfirmed = () => {
                this.hasToolbar &&
                    this.$refs.treeToolbar.classList.add('grayed-out');

                // Returns a promise for each node with concurrency throttling
                asyncPool(this.concurrencyMax, batchableNodes, (node) => {
                    let promise;

                    // Avoids exceptions when trying to show the loading state of invisible nodes
                    if (!node.vm) {
                        node.vm = { loading: true };
                    }

                    promise = treeComponent.tree.loadChildren(node).then(() => {
                        numChildren += node.children.length;
                    });

                    return promise;

                    // Notifies of the number of added nodes.
                })
                    .then(() => {
                        if (numChildren) {
                            let message = `${numChildren} new class nodes added.`;

                            // Filtered list or empty results => re-renders the whole filtered tree.
                            if (this.treeFilter) {
                                this.refreshFilter();
                            }

                            isNotification &&
                                this.$eventBus.$emit(
                                    'notification:show',
                                    'success',
                                    message,
                                    `Tree updated`
                                );
                        } else {
                            isNotification &&
                                this.$eventBus.$emit(
                                    'notification:show',
                                    'info',
                                    `Manually re-expanding nodes with dotted arrows may reveal more classes.`,
                                    `No new class nodes`,
                                    [],
                                    undefined,
                                    { timeout: 0 }
                                );
                        }

                        // Re-enables toolbar only after all requests have resolved (with or without an error).
                    })
                    .finally(() => {
                        this.hasToolbar &&
                            this.$refs.treeToolbar &&
                            this.$refs?.treeToolbar?.classList?.remove(
                                'grayed-out'
                            );
                    });
            };

            if (batchableNodes.length === 0) {
                isNotification &&
                    this.$eventBus.$emit(
                        'notification:show',
                        'info',
                        `Manually re-expanding existing class nodes may reveal more classes.`,
                        `No new class nodes`
                    );

                // Asks for confirmation when the number of nodes involved is high.
            } else if (batchableNodes.length > 1000) {
                isNotification &&
                    this.bulkConfirmation(batchableNodes, 'Probe', onConfirmed);
            } else {
                onConfirmed();
            }
        },

        bulkConfirmation(nodes, verb, onConfirmed) {
            this.$eventBus.$emit(
                'notification:show',
                'confirm',
                `Please note this process may temporarily slow down your browser. Do you wish to continue?`,
                `${verb} ${nodes.length} nodes`,
                [
                    {
                        text: 'Cancel',
                        action: (toast) => {
                            this.isTreeIdle = true;
                            this.$eventBus.$emit('notification:close', toast);
                        },
                    },
                    {
                        text: `${verb} all`,
                        action: (toast) => {
                            this.$eventBus.$emit('notification:close', toast);
                            this.$nextTick(() => {
                                onConfirmed();
                            });
                        },
                    },
                ],
                'leftTop'
            );
        },

        /**
         * Updates the tree view's state every time the expanded state of any node changes.
         * @param {Object|Object[]} nodes - Single node or array of nodes just expanded/collapsed.
         */
        onExpandStateChange(nodes) {
            this.isTreeExpanded = !this.hasCollapsed();
        },

        /**
         * It prevents the automatic collapse of nodes when clearing the filter if they were originally collapsed but have
         * subsequently been expanded interactively during the tree's filtered state. Aditionally, it notifies how
         * many children nodes have been added if multiple nodes were expanded (probably programmatically). Finally, it pre-emptively
         * fetches more nodes to give the illussion of a complete tree.
         * NOTE: Due to backend architecture, it is not known whether a given node has children or not until it is expanded.
         * @param {Object|Object[]} nodes - Single node or array of nodes just expanded.
         * @param {Boolean} [isGrow = false] - Grow the tree after expanding the nodes.
         * @see {grow}
         */
        onAfterExpand(isGrow, nodes) {
            let numChildren = 0;
            if (!Array.isArray(nodes)) {
                nodes = [nodes];
            }

            nodes.forEach((node) => {
                // Counts the number of children added after expansion
                if (node.children.length) {
                    numChildren += node.children.length;

                    if (this.treeFilter) {
                        // Some of the new children may not satisfy the filter query, i.e. they are unmatched nodes.
                        this.isPartialFilter = true;

                        // Forces the visibility of children in case any are unmatched nodes.
                        node.children.forEach((child) => child.show());
                    }
                }

                // Makes sure the node stays visible after clearing the filter
                if (this.treeFilter) {
                    node.__expanded = true;
                    node.recurseUp((parent) => {
                        parent.__expanded = true;
                    });
                }

                // Automatically fetches the children of those child nodes which are not new.
                // NOTE: new nodes will certainly have no children pending retrieval and, therefore, will not be the source of more growth.
                if (isGrow) {
                    this.grow(
                        node.children.filter((child) => {
                            return (
                                child.isBatch ||
                                (!child.isCreatedBefore &&
                                    !/\S+-NEW$/.test(child.data.id))
                            );
                        })
                    );
                }
            });

            if ((nodes.length > 1 || this.isPartialFilter) && numChildren) {
                if (this.isPartialFilter) {
                    this.$eventBus.$emit(
                        'notification:show',
                        'warning',
                        `${numChildren} unfiltered class nodes revealed. You may wish to reapply the filter.`,
                        `${nodes.length} class nodes expanded`
                    );
                }
            }
        },

        /**
         * Expands all collapsed nodes with children using an improved version of the tree API's "expandAll".
         * NOTE: the tree's API method "expandAll" tries to expand all nodes that are collapsed (visible or not).
         * Sometimes this leads to exceptions when trying to show the loading state of invisible nodes. Also, it provides no
         * common promise for all the expansion actions. Finally, it coalesces all events into a single one.
         */
        onExpandAll() {
            const treeComponent = this.$refs.tree;
            const visibleCollapsed = [];

            // Callback for confirmed action
            const onConfirmed = () => {
                this.isTreeIdle = false;

                // Returns a promise for each of the expanded nodes to cater for cases where a request is necessary.
                // NOTE: parallel requests are limited.
                // NOTE: the timeout is meant to give enough time for the busy state to render before continuing and potentially blocking JS.
                setTimeout(() => {
                    asyncPool(this.concurrencyMax, visibleCollapsed, (node) => {
                        let promise;

                        // Node is batchable => fetches children and avoids exceptions when showing the loading state of invisible nodes.
                        if (node.isBatch) {
                            if (!node.vm) {
                                node.vm = { loading: true };
                            }
                            promise = treeComponent.tree.loadChildren(node);

                            // Node has all its children already available => bypasses promise.
                        } else {
                            promise = Promise.resolve();
                        }

                        // This guarantees that all nodes will have been rendered by the time the global asyncPoool promise is resolved.
                        return promise.then(() => {
                            node.state('expanded', true);
                            return this.$nextTick();
                        });

                        // Triggers a custom event and sends the array of nodes just expanded.
                    }).then(() => {
                        treeComponent.$emit(
                            'tree:expandedAll',
                            visibleCollapsed
                        );
                        this.isTreeIdle = true;
                    });
                }, 500);
            };

            // Traverses the tree recursively to gather all the top-most (and therefore visible) collapsed nodes.
            treeComponent.recurseDown((node) => {
                if (node.collapsed() && node.hasChildren()) {
                    visibleCollapsed.push(node);
                    return false;
                } else {
                    return true;
                }
            });

            // Asks for confirmation when the number of nodes to be expanded is high.
            if (visibleCollapsed.length < 1000) {
                onConfirmed();
            } else {
                this.bulkConfirmation(visibleCollapsed, 'Expand', onConfirmed);
            }
        },

        resetData() {
            this.isTreeIdle = false;
            this.rootRenderCount = 0;
            this.onFilter('');
            useClassTree().incrementRenderCount();
            this.nodeCount = 0;
            this.selectCount = 0;
            this.isTreeIdle = true;
        },

        resetFilter() {
            this.onFilterClear();
            this.isFilterFuzzy = this.initFilterFuzzy;
            this.selFilterProps = this.initSelFilterProps;
            this.filterDisplay = this.initFilterDisplay;
            this.$refs.tree.tree.options.filter.plainList =
                this.filterDisplay === 'list';
        },

        onFilterClear() {
            this.filterQuery = '';
            this.onFilter(this.filterQuery);
        },

        onFilter(query) {
            const trimmedQuery = query.trim();

            if (trimmedQuery !== this.treeFilter) {
                this.isTreeIdle = false;
                setTimeout(() => {
                    this.treeFilter = trimmedQuery;
                }, 500);
            } else if (this.isPartialFilter) {
                this.refreshFilter();
            }
        },

        refreshFilter() {
            if (this.treeFilter) {
                this.isTreeIdle = false;
                setTimeout(() => {
                    this.$refs.tree.tree.filter(this.treeFilter);
                }, 500);
            }
        },

        onFiltered(matches) {
            const treeComponent = this.$refs.tree;
            let sortedMatches;
            let duplicates;
            let unmatchedPathNodes;

            // In case any nodes were left hidden from a previous filtered version of the tree (see below).
            this.unhideNodes();

            // Once the filter is changed (be that display mode or query), by definition, the filter is no longer partial.
            this.isPartialFilter = false;

            // Even when the filter field is emptied, the "tree:filtered" event is still triggered and, consequently, this handler.
            if (this.treeFilter) {
                // The plain list mode will show all the matches sorted by descending weight + case-insensitive alphabetically and stripped of repeated classes.
                if (this.filterDisplay === 'list') {
                    sortedMatches = orderBy(
                        matches,
                        [
                            'matchedWeight',
                            (node) => lowerFirst(node.data.primaryLabel),
                        ],
                        ['desc', 'asc']
                    );
                    duplicates = {};

                    // Builds a lookup table of all duplicate nodes by ID and type of node
                    treeComponent.matches = uniqWith(
                        sortedMatches,
                        (node1, node2) => {
                            const isDuplicate =
                                node1.data.id === node2.data.id &&
                                node1.data.typeOfNode === node2.data.typeOfNode;
                            const duplicateID =
                                node1.data.id + node1.data.typeOfNode;

                            if (isDuplicate) {
                                if (!duplicates[duplicateID]) {
                                    duplicates[duplicateID] = [];
                                }
                                if (
                                    duplicates[duplicateID].indexOf(node1) ===
                                    -1
                                ) {
                                    duplicates[duplicateID].push(node1);
                                }
                                if (
                                    duplicates[duplicateID].indexOf(node2) ===
                                    -1
                                ) {
                                    duplicates[duplicateID].push(node2);
                                }
                            }

                            return isDuplicate;
                        }
                    );

                    // Attaches the the lookup table available to each entry in the list of matches and makes reactive navigation possible.
                    treeComponent.matches.forEach((match) => {
                        match.duplicates = duplicates[
                            match.data.id + match.data.typeOfNode
                        ] || [match];
                        this.$set(match, 'dupIndex', 0);
                    });

                    // Ameliorates the limitations of a tree structure when conveying order by hiding unmatched classes.
                } else {
                    // Compensates the tree plugin's default behaviour of silently expanding all nodes when filtering.
                    treeComponent.tree.recurseDown((node) => {
                        if (!node.children.length && node.expanded()) {
                            node.state('expanded', false);
                        }
                    });

                    // Hides all unmatched nodes
                    unmatchedPathNodes =
                        treeComponent.findAll({ state: { matched: false } }) ||
                        [];
                    unmatchedPathNodes.forEach((node) => {
                        node.hide();
                    });

                    // All nodes in the path to a matched one are expanded.
                    matches.forEach((node) => {
                        node.recurseUp((parent) => {
                            parent.state('expanded', true);
                            parent.state('visible', true);
                        });
                    });
                }
            }

            // Updates the expand state of the tree after re-render.
            // NOTE: on filter mode, nodes are given shadow properties like "__expanded" to avoid changing state of the unfiltered tree.
            this.isTreeIdle = true;
            this.$nextTick(() => {
                this.onExpandStateChange();
            });
        },

        onFilterDisplayToggle(displayMode) {
            this.filterDisplay = displayMode;
        },

        /**
         * Generates the path to the class view corresponding to this node, taking into account HTML5 push state if necessary.
         * NOTE: When a b-link is disabled, the hash is not prefixed automatically to the resulting HREF attribute.
         * @param {Object} node - Node that just went through a state change.
         */
        nodeLink(node) {
            let path = `/ontologies/${
                node.data.entityUniqueID
            }/classes/${this.urlToString(node.data.primaryID)}`;

            if (this.isSelectable && this.isRouterHash) {
                return '#' + path;
            } else {
                return path;
            }
        },

        /**
         * Grabs a list of all the ancestor nodes to a given one except itself.
         * @param {Object} node - Node whose path is being determined.
         */
        nodePath(node) {
            const path = node.getPath();

            path.shift();
            return path;
        },

        /**
         * Determines if the current node has a distinctive relationship worth showing.
         * @param {Object} node - State and data object for the node.
         */
        hasRelationship(node) {
            return (
                node.data.typeOfNode && node.data.typeOfNode !== 'subClassOf'
            );
        },

        /**
         * Determines if any of the node's edited data properties have errors.
         * @param {Object} node - State and data object for the node.
         * @returns {Array} Properties with error.
         */
        hasError(node) {
            const editState = this.isEditable && node.data.editState;
            return Object.keys(editState).filter((property) => {
                return (
                    !editState[property].isSaving && editState[property].error
                );
            });
        },

        /**
         * Determines if any of the node's edited data properties has been edited successfully.
         * @param {Object} node - State and data object for the node.
         * @returns {Array} Properties edited so far.
         */
        hasEdit(node) {
            // @todo Uncomment and determine edits based on current server transaction.
            // if (useEdits().getEditIsActive.value) {
            //     const classId = node.data && node.data.id;
            //
            //     return useEdits().classHasEdits(classId) ? [1] : [];
            // }

            const editState = (this.isEditable && node.data.editState) || {};
            return Object.keys(editState).filter((property) => {
                return (
                    !editState[property].error &&
                    editState[property].isSaving === false
                );
            });
        },

        /**
         * Tests if a given node has any descendants that have undergone edits.
         * @param {Object} node - State and data object for the node.
         * @returns {Array} Properties edited so far.
         */
        hasEditedDescendant(node) {
            let hasEditedNode = [];

            node.recurseDown((descendant) => {
                if (hasEditedNode.length) {
                    return false;
                } else {
                    hasEditedNode = this.hasEdit(descendant);
                    return true;
                }
            });

            return hasEditedNode;
        },

        /**
         * Determines if there are other ways the class node is related to others apart from the relationship represented by
         * the property "typeOfNode".
         * @param {Object} node - State and data object for the node.
         * @returns {Array} Properties edited so far.
         */
        hasOtherRelationships(node) {
            const otherRelationships = without(
                this.relationshipProps,
                this.normalisedTypeOf(node),
                'superClasses'
            );

            return otherRelationships.filter((relProperty) => {
                return (
                    node.data.hasOwnProperty(relProperty) &&
                    node.data[relProperty].length
                );
            });
        },

        /**
         * Tests if the node provided is newly created.
         * NOTE: Right after inserting the new node in the tree, its label is set, triggering a primary label edit event.
         * @param {Object} node - State and data object for the node.
         *
         * @returns {boolean}
         */
        isNew(node) {
            const labelState =
                this.isEditable &&
                node.data.editState &&
                node.data.editState.primaryLabel;
            return labelState && labelState.isNew && !labelState.error;
        },

        /**
         * Tests if the node provided represents an obsolete class.s
         * NOTE: Obsolescence is signaled as a label change to an empty value.
         * @param {Object} node - State and data object for the node.
         */
        isObsolete(node) {
            const labelState =
                this.isEditable &&
                node.data.editState &&
                node.data.editState.primaryLabel;
            return labelState && labelState.isObsolete;
        },

        /**
         * Turns the type of node value into a class relationship name.
         * NOTE: The value designating the type of node given by the API does not always match property names for relationships.
         * @param {Object | string} node - State and/or data object for the node or string value for the node type.
         */
        normalisedTypeOf(node) {
            let normalisedTypeOf;

            if (node.constructor.name === 'Node') {
                normalisedTypeOf = node.data.typeOfNode;
            } else if (typeof node === 'object') {
                if (node.hasOwnProperty('data')) {
                    normalisedTypeOf = node.data.typeOfNode;
                } else {
                    normalisedTypeOf = node.typeOfNode;
                }
            } else {
                normalisedTypeOf = node;
            }

            if (this.typeOfRelMap.hasOwnProperty(normalisedTypeOf)) {
                normalisedTypeOf = this.typeOfRelMap[normalisedTypeOf];
            }

            return normalisedTypeOf;
        },

        /**
         * Retrieves all the nodes which are parents of the same class.
         * @param {Object} refNode - Child node representing the common class whose parents are to be determined.
         * @returns {Array} Parent nodes.
         */
        parentClassNodes(refNode) {
            const sameClasses = this.sameClassNodes(refNode);

            sameClasses.push(refNode);

            return sameClasses.map((classNode) => {
                return classNode.parent;
            });
        },

        /**
         * Finds all other nodes that represent the same class as a given node's. It factors in temporary IDs for
         * recently added nodes, which would otherwise not be considered the same class due to their different ID in comparison
         * with the ID provided by the server after class creation.
         * @param {Object} refNode - Node taken as a reference.
         * @param {boolean} [isMultiple = true] - If false, it gets only the first occurrence in the tree.
         * @param {boolean} [isReference = false] - If false, it excludes the original node being used for comparison.
         * @param {boolean | string} [isEqualType = false] - If false, it only includes nodes that are of the same type (relationship). A
         * specific node type can also be passed in. Otherwise, it compares the type against the reference node's.
         * @returns {Array} Nodes different from the reference one that represent the same class.
         */
        sameClassNodes(
            refNode,
            isMultiple = true,
            isReference = false,
            isEqualType = false
        ) {
            return Array.from(
                this.$refs.tree.find((node) => {
                    const isSameLabel =
                        node.data.primaryLabel === refNode.data.primaryLabel;
                    const isSameID = node.data.id === refNode.data.id;
                    const isNew = /\S+-NEW$/.test(node.data.id);
                    let isSameType;

                    if (typeof isEqualType === 'string') {
                        isSameType =
                            this.normalisedTypeOf(node) ===
                            this.normalisedTypeOf(isEqualType);
                    } else if (isEqualType === true) {
                        isSameType =
                            node.data.typeOfNode === refNode.data.typeOfNode;
                    } else {
                        isSameType = true;
                    }

                    return (
                        (isReference || node !== refNode) &&
                        ((isSameID && isSameType) || (isNew && isSameLabel))
                    );
                }, isMultiple) || []
            );
        },

        /**
         * Selects a tree node without emmiting the corresponding event.
         * @param {Object} node - Object for the node just selected. It must be part of the tree.
         * @param {boolean} [isMultiple = true] - True if multiple selection is allowed.
         */
        silentSelect(node, isMultiple = true) {
            const selectionIndex = node.tree.selectedNodes.indexOf(node);
            const tree = node.tree;

            // Node never selected before
            if (tree.find({ id: node.id }) && selectionIndex < 0) {
                tree.select(node, isMultiple);
                node.state('selected', true);

                // Node already selected => replaces the corresponding instance in the selection list
            } else if (selectionIndex >= 0) {
                tree.selectedNodes[selectionIndex] = node;
            }
        },

        /**
         * Sets node data whenever the node's virtual element is created
         * @param {Object} node - State and data pertaining the node.
         */
        onNodeCreated(node) {
            node.vm.$set(node, 'isHover', false);

            if (
                this.isSelectedOnRender &&
                node.data.primaryID === this.currPrimaryID
            ) {
                this.silentSelect(node);
            }
        },

        /**
         * Sets node data only when the node's virtual element is created for the first time, be that during tree mount,
         * on first parent expansion after children are fetched or when lazy loading additional children.
         * @param {Object} node - State and data pertaining the node.
         */
        onNodeFirstCreated(node) {
            // Keeps track of the next page to retrieve when fetching new child nodes.
            node['currPage'] = 0;

            // Makes all existing nodes batchable by default to allow any subsequent retrieval of children.
            // NOTE: Due to the indeterminate nature of the children list for all the nodes except the leaves, they are all made
            // batchable even if they turn out to have no children.
            // NOTE: A brand new node is never batchable because, by definition, it has no children pending retrieval.
            if (
                !node.children.length &&
                !/\S+-NEW$/.test(node.data.id) &&
                !this.isNew(node)
            ) {
                node.isBatch = true;
            } else if (!node.children.length) {
                node.isBatch = false;
            }

            // Moves the total children count coming from the API to outside the reactive part of the node to avoid automatic propagation
            // while expanding nodes and fetching children.
            node.vm.$set(
                node,
                'numberOfChildren',
                (node.numberOfChildren || 0) + node.data.numberOfChildren
            );
            delete node.data.numberOfChildren;

            // Makes sure the edit progress is tracked and reactive without resorting to a singleton.
            // NOTE: The edit state may have been synced from an existing node. For example, when a new child node (from expansion)
            // represents a class already in the tree. Eg: "digestive system - colon".
            if (!node.data.hasOwnProperty('editState')) {
                node.vm.$set(node.data, 'editState', {});
            }

            // Makes the node's "text" property an alias for its primary label.
            // NOTE: the tree plugin uses the "text" property internally to allow editing the label.
            Object.defineProperty(node.data, 'text', {
                enumerable: true,
                get: function () {
                    return this.primaryLabel;
                },
                set: function (newText) {
                    this.primaryLabel = newText;
                },
            });
        },

        /**
         * Sets node data before the whole tree is rendered for the first time. Given the restrictive time span, the actions
         * here will override those carried out in the previous two creation hooks.
         * @param {Object} node - State and data pertaining the node.
         * @see {@link https://amsik.github.io/liquor-tree/#Structure|Liquor-tree structure}
         * @see {@link https://amsik.github.io/liquor-tree/#Redefine-Structure-Example|Liquor-tree convert}
         */
        onNodePreset(node) {
            // Initially collapses and selects all nodes matching the currently focused class (the one primarily represented by the tree path).
            // NOTE: Page 1 of the selected class' children is already available but not rendered.
            if (node.data.primaryID === this.currPrimaryID) {
                node.isBatch = false;

                if (node.children.length) {
                    node.state('expanded', false);
                    node['currPage'] = 1;
                }

                // Expands all other nodes to expose the path to the selected class node.
                // NOTE: by default, all nodes in the path are only partially complete in terms of their children. Hence "isBatch" being true.
            } else if (node.children.length) {
                node.state('expanded', true);
                node.isBatch = true;
            }
        },

        /**
         * Makes sure any batch children appended to the tree are consistent data-wise with existing ones representing the same class.
         * @param {Object} parentNode - State and data object for the parent node.
         * @param {Object[]} newChildren - Array of data objects representing the children to be be appended under the parent node.
         * @param {number} total - Number of children for the parent node, including those yet to be retrieved.
         */
        onChildrenBeforeParse(parentNode, newChildren, total) {
            const normalisedChildren = newChildren.map((child) => ({
                data: child,
            }));
            const removedChildren = [];

            // Removes duplicate nodes, leaving the existing one as the first child. This takes care of the class addition case in relationships.
            // NOTE: Two nodes may represent the same class (same API ID or primary ID) but with different relationships (typeOf).
            const distinctChildren = normalisedChildren.filter((retrieved) => {
                return !parentNode.children.some((existing) => {
                    const isRepeated =
                        existing.data.primaryID === retrieved.data.primaryID &&
                        existing.data.typeOfNode === retrieved.data.typeOfNode;

                    // Corrects any previously existing number of total children since to account for the repeated children.
                    // NOTE: this case arises when a child rendered on tree mount is removed, re-appended to its batch parent and the latter expanded.
                    if (isRepeated && parentNode.numberOfChildren > 0) {
                        parentNode.numberOfChildren--;
                    }
                    return isRepeated;
                });
            });

            // New child nodes that represent the same class may have been added/removed => updates selection count on child retrieval.
            distinctChildren.forEach((distinctNode) => {
                if (distinctNode.data.primaryID === this.currPrimaryID) {
                    this.selectCount++;
                }

                if (this.isRemovedChild(distinctNode, parentNode)) {
                    removedChildren.push(distinctNode);
                    this.selectCount--;
                }
            });

            // The total number of children will have already been retrieved from the server once after the first page has been retrieved.
            if (parentNode.currPage) {
                total = 0;
            }

            // Updates the total number of children to avoid it being out of sync with the count the server has.
            // NOTE: before or during retrieval, child classes may have been added or removed as a result of relationship changes.
            parentNode.numberOfChildren =
                (parentNode.numberOfChildren || 0) +
                total -
                removedChildren.length;
            parentNode.currPage++;
            return without(distinctChildren, ...removedChildren);
        },

        /**
         * Determines if a given child node should be removed based on the latest relationship changes and obsolescence.
         * @param {Object} childNode - Data and state object representative of the child node in question.
         * @param {Object} parentNode - Data and state object for the child node's parent.
         */
        isRemovedChild(childNode, parentNode) {
            const existingEdit = this.editLog[childNode.data.id];
            const existingData = existingEdit && existingEdit.live;
            let isRemoved = false;
            let existingRel;
            let isCurrRelChange;
            let plainCopy;

            // Checks the new child node has properties that have been edited.
            // NOTE: the edit log is node-type agnostic.
            if (existingData && !isEmpty(existingData.editState)) {
                // The child node represents an obsolete class => ignores the child.
                if (existingEdit.isObsolete) {
                    isRemoved = true;

                    // Checks that the edited property is a relationship.
                } else {
                    existingRel = this.normalisedTypeOf(childNode);

                    // Does the new child node represent a relationship whose list has changed?
                    isCurrRelChange =
                        existingData.editState.hasOwnProperty(existingRel);

                    // Marks a child for removal if its relationship with the parent is no longer applicable
                    // NOTE: with no existing nodes of the same class, all bets are off (any new node for the same class will be left there).
                    if (
                        isCurrRelChange &&
                        existingData[existingRel].indexOf(
                            parentNode.data.primaryID
                        ) === -1
                    ) {
                        isRemoved = true;

                        // New child class changed but not removed => copies the class properties over to the new child with whatever edit info there is.
                        // NOTE: on assigment, the source object is modified yet again with Vue's observer property.
                    } else {
                        plainCopy = JSON.parse(JSON.stringify(existingData));
                        extend(
                            childNode.data,
                            omit(plainCopy, this.syncBlacklist)
                        );
                    }
                }
            }

            return isRemoved;
        },

        /**
         * Syncs the state of any batch child node with the rest of the tree.
         * @param {Object} parentNode - State and data object for the parent node.
         */
        onChildrenAfterParse(parentNode) {
            const treeComponent = this.$refs.tree;

            // When the filter is active and on tree mode, only updates the weight of each newly fetched child
            // node and re-renders if there are already matches and the filter is on tree mode.
            if (
                this.treeFilter &&
                treeComponent.matches.length &&
                this.filterDisplay === 'tree'
            ) {
                parentNode.children.forEach((child) => {
                    child.state(
                        'matched',
                        !!this.fuzzyMatcher(this.treeFilter, child)
                    );
                });
            }

            // The process of adding child nodes fetched from the server has finished.
            parentNode.isBatchingChildren = false;

            // Children added at this point are not the result of batching. Hence why it's done after signalling batching is done.
            // this.updateAddedChildren(parentNode);
        },

        /**
         * Adds a new child to a given parent node if any of the edited relationships across the tree involves said parent node.
         * @param {Object} parentNode - State and data object for the parent node.
         */
        updateAddedChildren(parentNode) {
            let changedRelNames;
            let diffRelPrimaryIDs;
            let childClassID;
            let classLog;
            let existingChild;
            let isNewChild;
            let newChildNode;

            // Checks all edited relationships of affected classes up to now.
            for (childClassID in this.editLog) {
                classLog = this.editLog[childClassID];

                if (!classLog.live || !classLog.live.editState) return;

                changedRelNames = intersection(
                    Object.keys(classLog.live.editState),
                    this.relationshipProps
                );

                // Only takes into account newly added primaryIDs corresponding to any class instance already in the tree.
                changedRelNames.forEach((relName) => {
                    diffRelPrimaryIDs = difference(
                        classLog.live[relName],
                        classLog.previous[relName]
                    );
                    isNewChild =
                        map(parentNode.children, 'data.id').indexOf(
                            childClassID
                        ) === -1;
                    if (
                        isNewChild &&
                        diffRelPrimaryIDs.indexOf(parentNode.data.primaryID) !==
                            -1
                    ) {
                        existingChild = parentNode.tree.find(
                            (node) => node.data.id === childClassID
                        );

                        // Adds the cloned version of the existing class instance to the current parent taking into account relationship type.
                        if (existingChild.length) {
                            newChildNode = existingChild[0].clone();
                            newChildNode.data.typeOfNode =
                                Object.keys(this.typeOfRelMap).find(
                                    (key) => this.typeOfRelMap[key] === relName
                                ) || relName;

                            parentNode.prepend(newChildNode);
                            this.onBranchCloned(existingChild[0]);
                        }
                    }
                });
            }
        },

        /**
         * Operations to be performed only after the tree's root node is mounted. Normally, this will occur when
         * all the tree nodes are guaranteed to have been mounted.
         * @param {Object} node - State and data pertaining the root node.
         */
        onRootRendered(node) {
            const selNodes = node.tree.selectedNodes;

            // Re-applies the filter in case the tree has been re-rendered with new data.
            node.tree.options.filter.plainList = this.filterDisplay === 'list';
            this.onFilter(this.filterQuery);

            // Notifies the outside world of the class currently hightlighted in the tree
            if (selNodes.length) {
                this.$emit('node:selected', node.tree.selectedNodes[0].data);
                node.tree.primeSelector = node.tree.selectedNodes[0];
                this.selectCount = this.sameClassNodes(
                    node.tree.primeSelector,
                    true,
                    true
                ).length;
            }

            // Gets initial tree stats
            node.tree.recurseDown((node) => this.nodeCount++);

            this.$emit('tree:rendered', node.tree);
        },

        /**
         * Every time the node is rendered, it builds the button to retrieve more children and makes sure the hover state is updated timely.
         * @param {Object} node - Node just mounted.
         */
        onNodeMounted(node) {
            const moreWrapperEl = document.createElement('div');
            const moreButtonEl = document.createElement('button');
            const anchorEl =
                node.vm.$el.getElementsByClassName('tree-anchor')[0];
            const treeComponent = this;

            // Horizontally aligns the progressive disclosure button with the list of children.
            moreButtonEl.className =
                'more-btn child-more-btn btn btn-outline-secondary btn-sm mb-1 ml-4';
            moreButtonEl.title = `Adds next ${this.childrenPageSize} class nodes of ${node.text}`;
            moreWrapperEl.appendChild(moreButtonEl);
            moreWrapperEl.style.paddingLeft = node.vm.paddingLeft;
            moreWrapperEl.className = 'more-container text-left';

            // Grabs children on button click, disabling while the request is in progress.
            moreButtonEl.addEventListener('click', function () {
                moreButtonEl.setAttribute('disabled', '');
                node.tree.loadChildren(node).then(function () {
                    moreButtonEl.removeAttribute('disabled', '');
                    if (node.children.length === node.numberOfChildren) {
                        moreButtonEl.classList.add('d-none');
                    }
                    treeComponent.grow(
                        node.children.filter((node) => node.isBatch)
                    );
                });
            });

            // Defers DOM append until the node is confirmed to have more children after first fetch.
            node.moreEl = moreWrapperEl;

            // Updates the hover flag whenever the mouse approaches the node label's container.
            anchorEl.addEventListener('mouseenter', () => {
                node.isHover = true;
            });
            anchorEl.addEventListener('mouseleave', () => {
                node.isHover = false;
            });

            // Adds a count of currently rendered children.
            node.vm.$el.setAttribute('length', node.children.length);
        },

        /**
         * Performs operations needed on render and any subsequent updates.
         * NOTE: Due to backend architecture, it is not known whether a given node has children or not until it is expanded.
         * @param {Object} node - Node that just went through a change.
         */
        onNodeChanged(node) {
            const arrowEl = node.vm.$el.getElementsByClassName('tree-arrow')[0];
            const hasMore = node.children.length < node.numberOfChildren;

            // Changes the markup to indicate the indeterminancy cue of the expand arrow
            if (node.isBatch) {
                arrowEl.removeAttribute('no-batch');
            } else {
                arrowEl.setAttribute('no-batch', '');
            }

            // Checks if the "load more" button is necessary.
            node.vm.$el.classList.toggle('more-children', hasMore);
            if (hasMore) {
                node.vm.$el.appendChild(node.moreEl);
            }

            // Updates the count of currently rendered children.
            node.vm.$el.setAttribute('length', node.children.length);
        },

        /**
         * Performs operations needed only after updating any data properties of a given node.
         * NOTE: updates are predicated on the vue component for the node in question being live since given the dependence on watchers.
         * @param {Object} node - Node that just went through an strict update.
         * @param {Object} newData - Version of the data the node is to be updated with.
         * @param {Object} oldData - Version of the data the node had before the update.
         */
        onNodeDataUpdated(node, newData, oldData) {
            const thisComponent = this;
            let plainData;

            // Prevents an event storm by reacting only to the first update event and syncing from there.
            // NOTE: Flag resetting is delayed so that any derived relationship changes do not cause a similar storm too.
            if (node.isUpdateDetected) {
                this.$nextTick(() => {
                    node.isUpdateDetected = false;
                });

                // Only syncs if the node is meant to stay
                // NOTE: new nodes start off with an empty label.
            } else if (newData.primaryLabel.length) {
                // Copies properties over to the existing data object without mutation.
                // NOTE: other nodes might not have been mounted yet. In that case, updates on those do not need to be
                // cancelled beforehand because they will not happen in the first place.
                this.sameClassNodes(node).forEach((classNode) => {
                    // Strips the data object of its getters and setters.
                    // NOTE: on assigment, source object is changed yet again with Vue's observer property. Hence doing it for every class node.
                    plainData = JSON.parse(JSON.stringify(newData));

                    classNode.isUpdateDetected = classNode.isMounted;

                    extend(
                        classNode.data,
                        omit(plainData, thisComponent.syncBlacklist)
                    );
                });

                // The node has been created recently but its initial data has been "rolled back" => removes the new class everywhere.
            } else {
                this.sameClassNodes(node, true, true).forEach((classNode) => {
                    classNode.remove();
                });
            }
        },

        /**
         * Hooks that triggers only when a new node first becomes selectable.
         * @param {object} node The node instance
         * @param {object} newData The node data property
         * @param {object} node The node previous data property
         */
        onNodeBecomesSelectable(node, newData, oldData) {
            this.selectNodeOnCreation(node);
        },

        /**
         * Selects a node as soon as it is created and becomes selectable.
         * @param {object} node The node instance
         * @returns {undefined|void}
         */
        selectNodeOnCreation(node) {
            if (!this.isNew(node)) {
                return;
            }

            node.select(true);
        },

        /**
         * Moves around a given class node to reflect the changes in the parent list describing a certain type of relationship.
         * NOTE: nodes representing the same class but not mounted yet will not trigger a call to this method.
         * @param {Object} node - Child node that is being moved or removed.
         * @param {String} relName - Name of the class property for the relationship being changed.
         * @param {Object} newParents - Array of primaryIDs for the nodes related to the child one.
         * @param {Object} oldParents - Previous array of primaryIDs.
         * @see {onNodeDataUpdated}
         */
        onNodeRelUpdated(node, relName, newParents, oldParents) {
            const typeOfNode =
                Object.keys(this.typeOfRelMap).find(
                    (key) => this.typeOfRelMap[key] === relName
                ) || relName;
            const difference = propValuesDifference(newParents, oldParents);

            // A parent class has been removed => searches for nodes with type equal to the type of relationship changed and
            // with that parent class and removes them.
            if (newParents.length < oldParents.length) {
                this.sameClassNodes(node, true, true, typeOfNode).forEach(
                    (classNode) => {
                        if (
                            classNode.parent &&
                            difference.indexOf(
                                classNode.parent.data.primaryID
                            ) !== -1
                        ) {
                            classNode.remove();
                        }
                    }
                );

                // A parent class has been added => searches for that class in the tree and adds the cloned node as a child.
            } else if (newParents.length > oldParents.length) {
                difference.forEach((primaryID) => {
                    const nodesToAdd = [];
                    const sameClassParents =
                        node.tree.find(
                            (parentNode) =>
                                parentNode.data.primaryID === primaryID,
                            true
                        ) || [];
                    let isCycle = false;

                    // Checks for cycles: any descendant of the newly added node is a class intance of the "destination parent"
                    node.recurseDown((childNode) => {
                        if (
                            !isCycle &&
                            childNode.data.primaryID === primaryID
                        ) {
                            isCycle = true;
                        }

                        return !isCycle;
                    });

                    // Node state could potentially change after tree insertion. Hence why cloning is done in bulk before prepending.
                    // NOTE: prevents cycle-induced loops by only adding the top parent and relying on later batching to retrieve the rest.
                    sameClassParents.forEach(() => {
                        const cloned = node.clone(!isCycle);

                        if (isCycle) {
                            cloned.children = [];
                        }

                        nodesToAdd.push(cloned);
                    });

                    // Replicates the node addition for all nodes of the same class, changing the type of node if the source had a different
                    // type to the relationship being changed.
                    nodesToAdd.forEach((newNode, index) => {
                        const parentNode = sameClassParents[index];

                        newNode.data.typeOfNode = typeOfNode;
                        parentNode.prepend(newNode);
                    });

                    this.onBranchCloned(node);
                });
            }
        },

        /**
         * Operations to be performed after cloning the topmost node of a tree branch.
         * @param {Object} - Data and state object representative of the branch's root node.
         */
        onBranchCloned(node) {
            // The cloned branch may have multiple instances of the currently selected class => updates the select count.
            // NOTE: nodes are not flagged as selected until they are rendered. Therefore, just searching for nodes flagged as selected is not enough.
            if (node.data.primaryID === this.currPrimaryID) {
                this.selectCount = this.sameClassNodes(
                    node.tree.primeSelector,
                    true,
                    true
                ).length;
            }
        },

        /**
         * Makes sure any selected child node about to be destroyed forces the update of the emitted data object. The
         * latter is replaced with another selected node that is still mounted and, therefore, still capable of
         * detecting changes and propagating them.
         * NOTE: watchers may be destroyed when the their corresponding node is destroyed.
         * @param {Object} node - Object for the node being destroyed.
         */
        onNodeCollapsed(node) {
            const childNodes = node.children;
            const primeSelector = node.tree.primeSelector;
            let selMountedNode;

            if (childNodes.indexOf(primeSelector) !== -1) {
                selMountedNode = node.tree.selectedNodes.find((selNode) => {
                    return selNode.isMounted && selNode !== primeSelector;
                });

                if (selMountedNode) {
                    this.$emit('node:selected', selMountedNode.data);
                }
            }
        },

        /**
         * Automatically selects any other classes equal to any given one that is already selected. It also makes
         * sure the selected node is not concealed if any of the nodes in the path to the node itself were originally
         * collapsed. Finally, it marks the node as the original one that triggered the others to be selected.
         * @param {Object} node - Object for the node just selected.
         */
        onNodeSelected(node) {
            let classNodes;

            this.$emit('node:selected', node.data);

            classNodes = this.sameClassNodes(node);
            classNodes.forEach((classNode) => {
                this.silentSelect(classNode, true);
            });
            node.expandTop(true);

            this.selectCount = classNodes.length + 1;

            if (this.treeFilter) {
                node.recurseUp((parent) => {
                    parent.__expanded = parent.expanded();
                });
            }

            node.tree.primeSelector = node;
        },

        /**
         * Adds support for direct editing by double clicking on the tree node.
         * @param {Object} node - Object for the node being edited.
         */
        onNodeDblClick(node) {
            if (!this.isObsolete(node) && !node.isEditing) {
                this.$emit('node:dblclick');
                this.onNodeEdit(node);
            }
        },

        /**
         * Adds a cancel button right next to the label edit field and brings the field's style in line with the app's.
         * @param {Object} node - Object for the node being edited.
         * @param {String} okLabel - Text to be displayed for the confirmation button.
         * @param {Function} cancelCallback - Logic to be executed once the edit is cancelled.
         * @param {Function} okCallback - Logic to be executed once the edit is confirmed.
         */
        onNodeEdit(
            node,
            parentNode,
            okLabel = 'Change',
            cancelCallback,
            okCallback
        ) {
            const inputEls = node.vm.$el.getElementsByClassName('tree-input');
            const cancelEl = document.createElement('span');
            const okEl = document.createElement('span');
            const treeComponent = this.$refs.tree;

            const toggleDisable = function () {
                treeComponent.$el.classList.toggle(
                    'tree-editing',
                    node.isEditing
                );
                node.vm.$el.classList.add('node-editing', node.isEditing);
                node.state('selectable', !node.isEditing);
            };

            const preventFn = function (event) {
                if (!event.target.classList.contains('tree-input')) {
                    event.preventDefault();
                }
            };

            // Aborts edit if unauthorised to make/suggest changes
            if (!this.$store.getters.canChange(node.data.sourceUniqueID)) {
                return;
            }

            // Sets up the DOM context (class and element-wise)
            okEl.className = 'ok-text-btn ml-2';
            okEl.textContent = okLabel;

            cancelEl.className = 'cancel-text-btn ml-1';
            cancelEl.textContent = 'Cancel';

            // Prevents the default save when blurring the field and restores the original label if edit cancelled.
            // Additionally, it inline-validates the new label by scanning the current tree for duplicates.
            // NOTE: Mouseup events trigger the selection of a node up the element chain. Hence stopping its propagation.
            window.addEventListener('mousedown', preventFn, true);

            okEl.addEventListener('mouseup', function (event) {
                node.stopEditing(inputEls[0].value);
                event.stopPropagation();
            });

            cancelEl.addEventListener('mouseup', function (event) {
                node.stopEditing(false);
                event.stopPropagation();
            });

            // Cleans up the markup after editing (be it by cancelling or not)
            this.$refs.tree.$once(
                'node:editing:stop',
                function (node, isChange) {
                    if (cancelEl.parentElement) {
                        cancelEl.parentElement.removeChild(cancelEl);
                    }
                    if (okEl.parentElement) {
                        okEl.parentElement.removeChild(okEl);
                    }
                    window.removeEventListener('mousedown', preventFn, true);
                    setTimeout(toggleDisable, 300);

                    // NOTE: Node creation will involve changing the new node's label from blank to whatever its name is.
                    if (cancelCallback && !isChange) {
                        cancelCallback();
                    } else if (okCallback && isChange) {
                        okCallback();
                    }
                }
            );

            // Adds/changes the markup only once the input field has been rendered.
            node.startEditing();

            this.$nextTick(function () {
                if (!inputEls[0].classList.contains('invalid')) {
                    toggleDisable();

                    inputEls[0].classList.add('form-control');
                    inputEls[0].parentElement.appendChild(okEl);
                    inputEls[0].parentElement.appendChild(cancelEl);

                    this.registerNodeEventListeners(inputEls[0], parentNode);
                }
            });
        },

        /**
         * Notifies the outside world of any valid change.
         * @param {Object} node - Object for the node whose label has been changed.
         * @param {String} newLabel - New text for the node.
         * @param {String} oldLabel - Old text for the node.
         */
        onNodeLabelChange(node, newLabel, oldLabel) {
            if (
                useClassView().whichSchemaVersion.value >= 2 &&
                oldLabel !== ''
            ) {
                const primaryLabelIri = useClassView().getPrimaryLabelIri.value;
                this.$emit(
                    'node:edit',
                    primaryLabelIri,
                    [newLabel],
                    [oldLabel],
                    node.data,
                    newLabel
                );

                return;
            }

            this.$emit(
                'node:edit',
                'primaryLabel',
                newLabel,
                oldLabel,
                node.data
            );
        },

        onNewBulkNodes(newBulkNodes) {
            if (!Array.isArray(newBulkNodes)) return;

            this.$emit('bulkNodes:edit', newBulkNodes);
        },

        /**
         * Creates and propagates a new stub node, rendering it straight away on label change.
         * @param {Object} parentNode - Node which the new child node is being added to.
         */
        onNodeNew(parentNode) {
            const treeComponent = this.$refs.tree;
            const nodeStub = this.createNodeStub(parentNode);
            let newNode;

            // No parent node provided => adds the new node as a root one.
            if (isEmpty(parentNode)) {
                newNode = treeComponent.prepend(nodeStub);
                treeComponent.$nextTick(() => {
                    this.onNodeEdit(newNode, parentNode, 'Add', () => {
                        newNode.remove();
                    });
                });

                // Adds the new node as a child of a certain parent node.
            } else {
                newNode = parentNode.prepend(nodeStub);

                // Expands the parent node silently to force rendering without batch retrieval of children.
                parentNode.state('expanded', true);

                // Waits until the new node is rendered to change into edit mode and override any node-creation-time settings.
                parentNode.vm.$nextTick(() => {
                    this.onNodeEdit(
                        newNode,
                        parentNode,
                        'Add',

                        // Undoes the node addition everywhere in the tree when cancelling label input.
                        () => {
                            newNode.remove();
                            this.sameClassNodes(newNode).forEach(
                                (classNode) => {
                                    classNode.remove();
                                }
                            );
                        },

                        // Adds the new node to all nodes that represent the same class as the parent node.
                        // BUG: right after creating a new node, if any of the other instances is hidden because the parent is collapsed and then it's selected and changed, the change doesn't get through.
                        () => {
                            this.sameClassNodes(parentNode).forEach(
                                (classNode) => {
                                    classNode.prepend(nodeStub);
                                }
                            );
                        }
                    );
                });
            }
        },

        /**
         * Updates the tree following any child node being added anywhere in the three.
         * @param {Object} parentNode - Node which the child node is being added to.
         * @param {Object} newNode - New child node.
         */
        onNodeAdded(parentNode, newNode) {
            let sameClasses;

            // No new node supplied => it's a root class
            if (typeof newNode === 'undefined') {
                newNode = parentNode;
                parentNode = null;
            }

            // Makes sure all descendants are subtracted to all relevant node counts.
            newNode.recurseDown((descendant) => {
                this.nodeCount++;
                if (descendant.selected()) {
                    this.selectCount++;
                }
            });

            sameClasses = this.sameClassNodes(newNode);

            // Performs these updates only if the node addition is not a consequence of batch loading.
            // NOTE: the number of children could be undefined if the parent node has not been rendered yet (eg: under a collapsed node).
            if (parentNode && !parentNode.isBatchingChildren) {
                // NOTE: the parent's child count is updated by default when retrieving children on batch. Otherwise, it has to be changed programmatically.
                // NOTE: node additions may happen to leaf nodes which, as batch nodes also do, have their numberOfChildren set to null.
                if (!parentNode.isBatch) {
                    this.$set(
                        parentNode,
                        'numberOfChildren',
                        (parentNode.numberOfChildren || 0) + 1
                    );
                }

                // Removes any root instance of the class if it's the sole such instance.
                // TODO: once the editing methods are segregated into its own mixin, maybe put this back outside the isBatchingChildren condition, where it was originally. Unreproducible bug has cropped up one time.
                if (sameClasses.length === 1 && sameClasses[0].isRoot()) {
                    sameClasses[0].remove();
                }
            }
        },

        /**
         * Updates the tree following any child node being removed anywhere in the three.
         * NOTE: by the time this point is reached, data changes have already been propagated.
         * @param {Object} parentNode - Node which the child node is being added to.
         * @param {Object} node - Data object for the node just removed.
         */
        onNodeDeleted(parentNode, node) {
            let classNodes;

            // Makes sure all descendants are subtracted from all relevant node counts.
            node.recurseDown((descendant) => {
                this.nodeCount--;
                if (descendant.selected()) {
                    this.selectCount--;
                }
            });

            // Makes non-new "orphan" classes in the tree (all its instances have been removed) appear as new root classes.
            if (
                !/\S+-NEW$/.test(node.data.id) &&
                !this.sameClassNodes(node, false).length
            ) {
                this.$refs.tree.prepend(node);
                node.data.typeOfNode = 'subClassOf';

                // Waits until the prepended node is actually rendered.
                this.$nextTick(() => {
                    // Uses the deleted node's data as the reference object for class details, preserving existing references from elsewhere.
                    node.tree.primeSelector.data = node.data;

                    // Keeps two-way data binding in place (guarantees the connection between tree and source object for class changes).
                    this.$emit('node:selected', node.tree.primeSelector.data);
                });

                // Makes sure any removed and selected node is not still tracked by the tree as selected.
                // NOTE: if the prime selector is removed, there will be a disconnect between the source object for the changes and the object
                // used for rendering the class details.
            } else if (
                node.selected() &&
                this.selectCount &&
                node.tree.primeSelector.id === node.id
            ) {
                classNodes = this.sameClassNodes(node, true, true);
                node.tree.primeSelector = classNodes.find(
                    (node) => node.isMounted
                );

                if (!node.tree.primeSelector) {
                    node.tree.primeSelector = classNodes[0];
                }

                // The class details on display from which the class deletion was triggered could correspond to an instance representing a different relationship type.
                node.data.typeOfNode = node.tree.primeSelector.data.typeOfNode;

                // Preserves references to the node.
                node.tree.primeSelector.data = node.data;

                // Makes sure changes to class details are subsequently detected and acted upon by guaranteeing the tree node backing those details is already rendered.
                this.mountAll([node.tree.primeSelector]).then(() => {
                    this.$emit('node:selected', node.tree.primeSelector.data);
                });
            }

            if (parentNode) {
                if (parentNode.numberOfChildren) {
                    this.$set(
                        parentNode,
                        'numberOfChildren',
                        parentNode.numberOfChildren - 1
                    );

                    // Forces the update of the more button's state if the parent node is rendered.
                    if (parentNode.vm && parentNode.vm.$el) {
                        this.onNodeChanged(parentNode);
                    }
                }

                // Collapses the parent node automatically if no child nodes are left after removal.
                if (parentNode.expanded() && !parentNode.children.length) {
                    parentNode.collapse();
                }
            }
        },

        /**
         * Updates the the path from root to be shown in the filtered list's carousel of paths.
         * @param {Object} node - Filtered node for the class whose paths are being probed.
         * @param {boolean} isNext - True if the path to be displayed is the next one in the list.
         */
        onPathNav(node, isNext = true) {
            let pathIndex = node.dupIndex;

            if (isNext) {
                pathIndex++;
            } else {
                pathIndex--;
            }

            node.dupIndex = pathIndex;
        },

        /**
         * Adds classes in bulk through the bulk add classes dialogue.
         * @param parentNode
         * @param newClassesLabels
         */
        bulkAddClasses(parentNode, newClassesLabels) {
            parentNode.state('expanded', true);
            this.addBulkClasses(newClassesLabels, parentNode);
            this.currentBulkModal.isOpen = false;
        },
        toggleBulkAddModal(node, event) {
            this.currentBulkModal = {
                isOpen: event,
                nodeId: node.id,
            };
            return true;
        },

        toggleMergeClassesModal(node, event) {
            this.currentBulkModal = {
                isOpen: event,
                nodeId: node.id,
            };
            return true;
        },

        isHoverOrModalOpen(node) {
            if (
                this.currentBulkModal.nodeId === node.id ||
                this.classMergeModal.nodeId === node.id
            ) {
                return (
                    this.currentBulkModal.isOpen || this.classMergeModal.isOpen
                );
            }
        },

        pagination(e) {
            // // Check the scroll height of the tree to see if it is at the bottom
            // const scrolledToBottom =
            //     e.target.scrollTop + (e.target.offsetHeight + 80) >=
            //     e.target.scrollHeight;
            //
            // // Disable the load more button
            // const moreBtnEl = e.target.getElementsByClassName('more-btn')[0];
            // if (moreBtnEl) moreBtnEl.disabled = true;
            // if (scrolledToBottom) {
            //     this.$emit('tree:paginate');
            // }
        },

        buildNodeTestId(nodeText) {
            if (typeof nodeText === 'undefined') return;
            const suffix = nodeText.split(' ').join('-').toLowerCase();
            return 'testid__hierarchy-tree__node__' + suffix;
        },

        isMerging(node) {
            const mergeChanges = useEdits().getMergeEditChanges.value;
            if (!mergeChanges.length) return false;
            return mergeChanges.find(
                (mergeNode) => mergeNode.primaryLabel === node.data.primaryLabel
            );
        },
    },

    mixins: [singleNodeMixin, componentInjector],
};
</script>

<style scoped lang="scss">
@import 'src/scss/variables.scss';
.tree {
    overflow-anchor: none;
}

.svg-icon {
    margin-top: -0.1em;
    fill: currentColor;

    &.fetch-icon {
        height: 1.2em;
    }
}

.dropdown-header {
    display: flex;
    justify-content: space-between;
    padding: 0.5rem 1rem;
    width: 100%;
}
.dropdown-subheading {
    > .dropdown-header {
        padding-bottom: 0.5rem;
    }
}

.tree-toolbar {
    z-index: calc(#{$zindex-sticky} - 1);
    padding: 0.45rem 0.45rem 0;
    background: darken($white, 2%);

    &.grayed-out {
        &:after {
            background: rgba($light, 0.5);
        }
    }

    .input-group {
        flex-grow: 1;
        transition: box-shadow 200ms ease-in;

        &.focus {
            box-shadow: rgba($gray-400, 0.1) 0 0.1rem 0.25rem,
                rgba($gray-400, 0.08) 0 0.25rem 0.75rem !important;
        }

        &:hover,
        &.focus {
            .filter-input {
                border-color: rgba($secondary, 0.3) !important;
            }

            .filter-dropdown ::v-deep .dropdown-toggle {
                border-top-color: rgba($secondary, 0.3) !important;
                border-bottom-color: rgba($secondary, 0.3) !important;
            }

            .properties-filter ::v-deep .dropdown-toggle {
                border-left-color: rgba($secondary, 0.3) !important;
            }
        }
    }

    ::v-deep .filter-dropdown {
        &.properties-filter .dropdown-toggle {
            border-top-left-radius: $border-radius !important;
            border-bottom-left-radius: $border-radius !important;
        }

        .prop-filter-reset {
            line-height: 1;
        }
    }

    .setting-filter .btn:first-child {
        border-left: none !important;
    }

    .filter-field {
        position: relative;
        flex-grow: 1;

        .filter-input {
            border: 1px solid $gray-200 !important;
            border-left: none !important;
            border-right: none !important;
            border-radius: 0;
            box-shadow: none;
        }

        .clear-btn {
            position: absolute;
            top: -0.1rem;
            right: 0;
            padding: 0 0.6rem 0.17rem 0.4rem;
            font-size: 1.2rem;
            text-decoration: none;
            opacity: 0.6;

            &:not(:hover) {
                color: $text-muted;
            }
        }
    }

    .filter-btn {
        border-top-right-radius: $border-radius !important;
        border-bottom-right-radius: $border-radius !important;

        .filter-partial & path {
            fill: url(#partial-fill);
        }
    }

    .partial-fill-def {
        position: absolute;
        width: 0;
        height: 0;
    }

    .display-btn {
        z-index: auto !important;
        background: $secondary !important;
        opacity: 0.5;

        &:hover:not(.active) {
            opacity: 0.75;
        }

        &.active {
            opacity: 1;
        }
    }

    ::v-deep .dropdown {
        .btn:first-child {
            z-index: auto;
        }
    }

    .info-icon {
        margin-left: -0.25rem;
        color: darken($info, 7%);
    }
}

.filtered.filter-list ::v-deep .tree-content {
    margin: 0.3rem 0;
    background: darken($white, 2%);

    &,
    .tree-anchor {
        padding: 0;
    }
}

.node-container {
    border-radius: 1em;

    .filtered.filter-list & {
        width: 100%;
        padding: 0.5rem;
        text-align: left;
        white-space: normal;
    }

    .node-text-wrapper {
        .filtered.filter-list & {
            overflow: hidden;
            display: inline-block;
            max-width: 350px;
            text-overflow: ellipsis;
            white-space: nowrap;
        }
    }

    .node-text,
    .dupnode-text {
        color: inherit;
        text-decoration: none !important;
        user-select: text;

        &:not(.selectable):hover {
            color: $link-hover-color;
        }

        &.obsolete-class {
            color: $gray-600 !important;
            text-decoration: line-through !important;

            &:after {
                display: none !important;
            }
        }
    }

    .edit-state-hint {
        font-size: 44%;
    }

    &.highlighted .node-text {
        color: $secondary;
        font-weight: 500;
    }

    &.has-relationship {
        .node-text:before {
            margin-right: 0.3em;
            @include relationship-bubble;
        }
    }

    &.has-fetched-children .node-text:after {
        content: '(' attr(data-children) ')';
        display: inline-block;
        margin-top: -0.25em;
        margin-left: 0.3em;
        font-size: 0.8em;
        font-weight: normal;
        color: $text-muted;
        vertical-align: middle;

        .filtered.filter-list & {
            display: none;
        }
    }
}

.dupnode-container.has-relationship .dupnode-text:before {
    @include relationship-bubble;
}

::v-deep .tree-root {
    margin-bottom: 0;

    .tree-children {
        display: flex;
        flex-wrap: wrap;
    }
}

::v-deep .tree-node {
    display: inline-flex;
    min-width: 100%;

    .tree-editing &:not(.node-editing) > .tree-content {
        pointer-events: none;
        opacity: 0.6;
        transition: opacity 0.3s ease;
    }

    .tree-editing &.node-editing .tree-arrow {
        pointer-events: none;
    }

    .tree-anchor {
        margin-left: 0;
        padding-left: 0;

        .tree-input {
            height: 25.88px !important;
            border-color: lighten($info, 25%) !important;

            &:focus,
            &:hover {
                border-color: $info !important;
                box-shadow: rgba($info, 0.1) 0 0.1rem 0.25rem,
                    rgba($info, 0.08) 0 0.25rem 0.75rem;
            }

            &.invalid {
                border-color: rgba($danger, 0.6) !important;
            }
        }

        .cancel-text-btn,
        .ok-text-btn {
            padding: 0 0.3rem;
            border: 1px solid transparent;
            border-radius: $border-radius;
            transtion: $btn-transition;
        }

        .ok-text-btn {
            color: #fff;
            background: $info;
        }
        .cancel-text-btn {
            color: $gray-700;
            &:hover {
                border-color: $gray-700;
            }
        }
    }

    .more-container {
        display: none;

        .more-btn:after {
            content: 'Load more';
        }
    }

    &.more-children.expanded > .more-container {
        display: block;
    }

    &.loading,
    &.has-child.loading {
        font-size: 1em;

        &:before,
        &:after {
            content: none;
        }

        & > .tree-content .tree-arrow.has-child {
            left: 12px;
            margin-right: 15px;
            font-size: inherit;
            border: none;
            animation: none;
            transform: none;

            &:before {
                @include spinner;
            }

            &:after {
                content: none;
            }
        }

        & > .more-container .more-btn {
            border: none;

            &:after {
                content: 'Loading children...';
                @include pulse-color-animation;
            }
        }
    }
}

@include pulse-color-keyframes($secondary);

::v-deep .tree-arrow {
    &:not(.has-child) {
        position: relative;
        margin-left: 0;
        width: 30px;
        cursor: default;

        &:before,
        &:after {
            position: absolute;
            top: 50%;
            left: 50%;
        }

        &:after {
            margin-left: -3px;
            margin-top: -2px;
            height: 9px;
            width: 12px;
            border-style: solid;
            border-width: 0 0 2px 0;
            border-color: $gray-500 !important;
            transform: translate(-50%, -50%) translateX(0);
        }

        &:before {
            content: '';
            display: block;
            margin-top: 2px;
            height: 5px;
            width: 5px;
            border-radius: 50%;
            background: $gray-500;
            transform: translateY(-50%) translateX(0);
        }
    }
    &:after,
    &.has-child:after {
        -webkit-backface-visibility: hidden;
        border-color: $secondary;
    }
    &.expanded {
        &:after,
        &.has-child:after {
            border-color: $link-hover-color;
        }
    }

    &.has-child:after {
        border-style: dotted;
        border-right-width: 2px;
        border-bottom-width: 2px;
    }
    &.has-child[no-batch]:after {
        border-style: solid !important;
    }

    .filtered.filter-list & {
        display: none;
    }
}

.prev-path,
.next-path {
    color: lighten($secondary, 8%);

    &:hover {
        color: $secondary;
        cursor: pointer;
    }
}

::v-deep .carousel {
    .carousel-item {
        outline: none !important;

        .carousel-caption {
            display: none;
        }
    }
}

::v-deep .tree-node:not(.selected) > .tree-content {
    transition: $btn-transition;

    &:hover,
    &:focus {
        outline: none;
    }

    &:hover {
        background: $mark-bg;
    }

    &:focus {
        background: $light;
    }

    &:focus {
        box-shadow: inset 0px 0px 0px 1px $secondary;
    }
}

::v-deep .tree-node.selected > .tree-content {
    background: $mark-bg;
}

[data-score='3'] {
    background: lighten($success, 35%);
}
[data-score='2'] {
    background: $light-info;
}
[data-score='1'] {
    background: $gray-200;
}

.loading {
    position: relative;
    display: inline-block;
    margin-top: 1.7rem;
    font-size: 1.2em;

    &:after {
        content: 'Processing...';
        font-weight: 500;
        color: $text-muted;
    }
}
</style>

<style lang="scss">
@import 'src/scss/variables.scss';

.summary-children {
    font-size: 90%;
    color: $text-muted;
}
</style>
